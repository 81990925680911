import React, { useState, useEffect } from "react";
import "../OrgDashboard/OrgDashboard.css";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import OrgDashboardSidebar from "./OrgDashboardSidebar.jsx";
import { RxCross2 } from "react-icons/rx";
import {
  FaCamera,
  FaAngleLeft,
  FaAngleRight,
  FaMoneyBill,
  FaBox,
  FaEye,
  FaPlus,
  FaTicketAlt,
} from "react-icons/fa";
import img1 from "../../assets/img/event-imgs/img-1.jpg";
import { FaGauge, FaArrowTrendUp } from "react-icons/fa6";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst.js";
import axios from "axios";
import { toast } from "react-toastify";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function OrgDashboard() {
  const [text_state, setText_state] = useState("State");
  const [text_zipcode, setText_zipcode] = useState("Zipcode");
  const [text_city, setText_city] = useState("City");
  const [text_dashboard, setText_dashboard] = useState("Dashboard");
  const [text_add, setText_add] = useState("Add");
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_daily, setText_daily] = useState("Daily");
  const [text_weekly, setText_weekly] = useState("Weekly");
  const [text_monthly, setText_monthly] = useState("Monthly");
  const [text_noticeorg, setText_noticeorg] = useState(
    "See the graphical representation below"
  );
  const [text_ticketsales, setText_ticketsales] = useState("Ticket Sales");
  const [text_pageviews, setText_pageviews] = useState("Page Views");
  const [text_orders, setText_orders] = useState("Orders");
  const [text_revenue, setText_revenue] = useState("Revenue");
  const [text_previousperiod, setText_previousperiod] = useState(
    "From Previous Period"
  );
  const [text_selectedeve, setText_selectedeve] = useState("Selected Events");
  const [text_createseasonpass, setText_createseasonpass] =
    useState("Create Season Pass");
  const [btn_create_event, setBtn_create_event] = useState("Create Event");
  const [text_createleague, setText_createleague] = useState("Create League");
  const [text_createteam, setText_createteam] = useState("Create Team");


  const navigate = useNavigate();
  const [orgName, setOrgname] = useState("");
  const [profileLink, setProfileLink] = useState("");
  const [about, setAbout] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [Address1, setAddress1] = useState("");
  const [Address2, setAddress2] = useState("");
  const [Country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profileImgUrl, setProfileImgUrl] = useState("");

  const handleModalclose = () => {
    console.log("Not Add Organization");
  };

  const getProfielInfo = () => {
    var API_URL = API_DOMAIN + "user/profile/get";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var data = response?.data?.data;
        console.log("profile data", data);
        setProfileImgUrl(data?.ProfileURL);
        setFirstName(data?.firstname);
        setLastName(data?.lastname);
      })
      .catch(function (error) {
        console.log(error);
        // errorToast(err);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          });
        } else {
          // if (totalAttendingTicket !== "") {
          toast.error(error.response.data.message);
          // }
        }
      })
      .then(function () {});
  };

  useEffect(() => {
    getProfielInfo();
  }, []);
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_state(
        xpath.select1("/resources/string[@name='text_state']", doc).firstChild
          .data
      );
      setText_zipcode(
        xpath.select1("/resources/string[@name='text_zipcode']", doc).firstChild
          .data
      );
      setText_city(
        xpath.select1("/resources/string[@name='text_city']", doc).firstChild
          .data
      );
      setText_dashboard(
        xpath.select1("/resources/string[@name='text_dashboard']", doc)
          .firstChild.data
      );
      setText_createseasonpass(
        xpath.select1("/resources/string[@name='text_createseasonpass']", doc)
          .firstChild.data
      );
      setText_selectedeve(
        xpath.select1("/resources/string[@name='text_selectedeve']", doc)
          .firstChild.data
      );
      setText_previousperiod(
        xpath.select1("/resources/string[@name='text_previousperiod']", doc)
          .firstChild.data
      );
      setText_revenue(
        xpath.select1("/resources/string[@name='text_revenue']", doc).firstChild
          .data
      );
      setText_orders(
        xpath.select1("/resources/string[@name='text_orders']", doc).firstChild
          .data
      );
      setText_pageviews(
        xpath.select1("/resources/string[@name='text_pageviews']", doc)
          .firstChild.data
      );
      setText_ticketsales(
        xpath.select1("/resources/string[@name='text_ticketsales']", doc)
          .firstChild.data
      );
      setText_noticeorg(
        xpath.select1("/resources/string[@name='text_noticeorg']", doc)
          .firstChild.data
      );
      setText_monthly(
        xpath.select1("/resources/string[@name='text_monthly']", doc).firstChild
          .data
      );
      setText_weekly(
        xpath.select1("/resources/string[@name='text_weekly']", doc).firstChild
          .data
      );
      setText_daily(
        xpath.select1("/resources/string[@name='text_daily']", doc).firstChild
          .data
      );
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_add(
        xpath.select1("/resources/string[@name='text_add']", doc).firstChild
          .data
      );
      setText_createleague(
        xpath.select1("/resources/string[@name='text_createleague']", doc)
          .firstChild.data
      );
      setBtn_create_event(
        xpath.select1("/resources/string[@name='btn_create_event']", doc)
          .firstChild.data
      );
       setText_createteam(
         xpath.select1("/resources/string[@name='text_createteam']", doc)
           .firstChild.data
       );
    } catch (ert) {}
  };

  return (
    <>
      <Header />
      <OrgDashboardSidebar />
      <div
        className="modal-org fade"
        id="addorganisationModal"
        tabindex="-1"
        aria-labelledby="addorganisationLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-bold" id="addorganisationLabel">
                OrganiZation details
              </h5>
              <button
                type="button"
                className="close-model-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <RxCross2 className="uil-multiply" />
              </button>
            </div>
            <div className="modal-body">
              <div className="model-content main-form">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group text-center mt-4">
                      <label className="form-label fw-bold">Avatar*</label>
                      <span className="org_design_button btn-file">
                        <span>
                          <FaCamera className="camera-icon" />
                        </span>
                        <input
                          type="file"
                          id="org_avatar"
                          accept="image/*"
                          name="Organisation_avatar"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">Name*</label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder=""
                        value={orgName}
                        onChange={(e) => setOrgname(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">
                        Profile Link*
                      </label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder=""
                        value={profileLink}
                        onChange={(e) => setProfileLink(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">About*</label>
                      <textarea
                        className="form-textarea"
                        placeholder=""
                        value={about}
                        onChange={(e) => setAbout(e.target.value)}
                      >
                        About
                      </textarea>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">Email*</label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder=""
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">Phone*</label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder=""
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">Website*</label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder=""
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">Facebook*</label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder=""
                        value=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">Instagram*</label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder=""
                        value=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">Twitter*</label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder=""
                        value=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">LinkedIn*</label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder=""
                        value=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">Youtube*</label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder=""
                        value=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <h4 className="address-title fw-bold">Address</h4>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">Address 1*</label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder=""
                        value={Address1}
                        onChange={(e) => setAddress1(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">Address 2*</label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder=""
                        value={Address2}
                        onChange={(e) => setAddress2(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group main-form mt-4">
                      <label className="form-label fw-bold">Country*</label>
                      <select
                        className="selectpicker-add-org mb-2"
                        data-size="5"
                        title="Nothing selected"
                        data-live-search="true"
                        value={Country}
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        <option value="Algeria">Algeria</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria (Österreich)</option>
                        <option value="Belgium">Belgium (België)</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Brazil">Brazil</option>
                        <option value="Canada">Canada</option>
                        <option value="Chile">Chile</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Dominican Republic">
                          Dominican Republic
                        </option>
                        <option value="Estonia">Estonia</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="Germany">Germany</option>
                        <option value="Greece">Greece</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Japan">Japan</option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Norway">Norway</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Korea">South Korea</option>
                        <option value="Spain">Spain</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Tanzania">Tanzania</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Turkey">Turkey</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="Vietnam">Vietnam</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">
                        {text_state}*
                      </label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder=""
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">{text_city}*</label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder=""
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">
                        {text_zipcode}*
                      </label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder=""
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="co-main-btn min-width"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => handleModalclose()}
              >
                {text_cancel}
              </button>
              <button
                type="button"
                className="main-btn-org-popup min-width btn-hover "
              >
                {text_add}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="otherPages">
        <div className="wrapper wrapper-body" id="wrapper-body">
          <div className="dashboard-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-main-title">
                    <h3>
                      <FaGauge className="me-3" />
                      {text_dashboard}
                    </h3>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="main-card add-organisation-card p-4 mt-5">
                    <div className="ocard-left">
                      <div className="ocard-avatar">
                        <img
                          src={profileImgUrl === "" ? img1 : profileImgUrl}
                          alt=""
                        />
                      </div>
                      <div className="ocard-name">
                        <h4>
                          {" "}
                          {sessionStorage.getItem("userfirstname")} {""}
                          {sessionStorage.getItem("userlastname")}
                        </h4>
                        <span>{sessionStorage.getItem("orgName")}</span>
                      </div>
                    </div>
                    <div className="ocard-right">
                      <div className="mb-4 org-button-create">
                        <button
                          onClick={() => navigate("/create-league")}
                          className="offcanvas-create-btn "
                        >
                          <span>{text_createleague}</span>
                        </button>
                        <button
                          onClick={() => navigate("/create-team")}
                          className="offcanvas-create-btn "
                        >
                          <span>{text_createteam}</span>
                        </button>
                        <button
                          onClick={() => navigate("/event-type")}
                          className="offcanvas-create-btn "
                        >
                          <span>{btn_create_event}</span>
                        </button>
                      </div>
                      <div className="org-button-create gap-3">
                        <button
                          className=" co-main-btn co-main-btn-responsive "
                          onClick={() => navigate("/create-seasonal")}
                        >
                          <i className="fa-solid fa-plus mx-2">
                            <FaPlus className="mb-1" />
                          </i>
                          {text_createseasonpass}
                        </button>
                        <button
                          className=" co-main-btn co-main-btn-responsive"
                          onClick={() => navigate("/create-bundle")}
                        >
                          <i className="fa-solid fa-plus mx-2">
                            <FaPlus className="mb-1" />
                          </i>
                          Create Bundle Pass
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="main-card mt-4">
                    <div className="dashboard-wrap-content">
                      <div className="d-flex flex-wrap justify-content-between align-items-center p-4">
                        <div className="dashboard-date-wrap d-flex flex-wrap justify-content-between align-items-center">
                          <div className="dashboard-date-arrows">
                            <Link to="#" className="before_date">
                              <FaAngleLeft className="left-arrow" />
                            </Link>
                            <Link to="#" className="after_date disabled">
                              <FaAngleRight className="left-arrow" />
                            </Link>
                          </div>
                          <h5 className="dashboard-select-date fw-bold">
                            <span>1st April, 2022</span>-
                            <span>30th April, 2022</span>
                          </h5>
                        </div>
                        <div className="rs">
                          <div className="dropdown dropdown-text event-list-dropdown">
                            <button
                              className="dropdown-toggle event-list-dropdown"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span>{text_selectedeve} (1)</span>
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <Link className="dropdown-item" to="#">
                                  1
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="dashboard-report-content ">
                        <div className="row">
                          <div className="col-xl-3 col-lg-6 col-md-6">
                            <div className="dashboard-report-card purple">
                              <div className="card-content">
                                <div className="card-content">
                                  <span className="card-title fs-6">
                                    {text_revenue} (AUD)
                                  </span>
                                  <span className="card-sub-title fs-3">
                                    $550.00
                                  </span>
                                  <div className="d-flex align-items-center">
                                    <span>
                                      <FaArrowTrendUp className="search--icon" />
                                    </span>
                                    <span className="text-Light font-12 ms-2 me-2">
                                      0.00%
                                    </span>
                                    <span className="font-12 color-body text-nowrap">
                                      {text_previousperiod}
                                    </span>
                                  </div>
                                </div>
                                <div className="card-media">
                                  <FaMoneyBill className="moneybill-icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-6">
                            <div className="dashboard-report-card red">
                              <div className="card-content">
                                <div className="card-content">
                                  <span className="card-title fs-6">
                                    {text_orders}
                                  </span>
                                  <span className="card-sub-title fs-3">2</span>
                                  <div className="d-flex align-items-center">
                                    <span>
                                      <FaArrowTrendUp className="search--icon" />{" "}
                                    </span>
                                    <span className="text-Light font-12 ms-2 me-2">
                                      0.00%
                                    </span>
                                    <span className="font-12 color-body text-nowrap">
                                      {text_previousperiod}
                                    </span>
                                  </div>
                                </div>
                                <div className="card-media">
                                  <FaBox className="moneybill-icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-6">
                            <div className="dashboard-report-card info">
                              <div className="card-content">
                                <div className="card-content">
                                  <span className="card-title fs-6">
                                    {text_pageviews}
                                  </span>
                                  <span className="card-sub-title fs-3">
                                    30
                                  </span>
                                  <div className="d-flex align-items-center">
                                    <span>
                                      <FaArrowTrendUp className="search--icon" />{" "}
                                    </span>
                                    <span className="text-Light font-12 ms-2 me-2">
                                      0.00%
                                    </span>
                                    <span className="font-12 color-body text-nowrap">
                                      {text_previousperiod}
                                    </span>
                                  </div>
                                </div>
                                <div className="card-media">
                                  <FaEye className="moneybill-icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-6">
                            <div className="dashboard-report-card success">
                              <div className="card-content">
                                <div className="card-content">
                                  <span className="card-title fs-6">
                                    {text_ticketsales}
                                  </span>
                                  <span className="card-sub-title fs-3">3</span>
                                  <div className="d-flex align-items-center">
                                    <span>
                                      <FaArrowTrendUp className="search--icon" />{" "}
                                    </span>
                                    <span className="text-Light font-12 ms-2 me-2">
                                      0.00%
                                    </span>
                                    <span className="font-12 color-body text-nowrap">
                                      {text_previousperiod}
                                    </span>
                                  </div>
                                </div>
                                <div className="card-media">
                                  <FaTicketAlt className="moneybill-icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main-card mt-4">
                    <div className="d-flex flex-wrap justify-content-between align-items-center border_bottom p-4">
                      <div className="dashboard-date-wrap d-flex flex-wrap justify-content-between align-items-center">
                        <div className="select-graphic-category">
                          <div className="form-group main-form mb-2 ">
                            <select
                              className="selectpicker select-org"
                              data-width="150px"
                            >
                              <option value="revenue">{text_revenue}</option>
                              <option value="orders">{text_orders}</option>
                              <option value="pageviews">
                                {text_pageviews}
                              </option>
                              <option value="ticketsales">
                                {text_ticketsales}
                              </option>
                            </select>
                          </div>
                          <small className="mt-4">{text_noticeorg}</small>
                        </div>
                      </div>
                      <div className="rs">
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio1"
                          />
                          <label
                            className="btn btn-outline-primary"
                            for="btnradio1"
                          >
                            {text_monthly}
                          </label>
                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio2"
                            checked
                          />
                          <label
                            className="btn btn-outline-primary"
                            for="btnradio2"
                          >
                            {text_weekly}
                          </label>
                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio3"
                          />
                          <label
                            className="btn btn-outline-primary"
                            for="btnradio3"
                          >
                            {text_daily}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="item-analytics-content p-4 ps-1 pb-2">
                      <div id="views-graphic"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrgDashboard;
