import React, { useState } from "react";
import "../LandingPage/LandingPage.css";
import "../Team/CreateTeam.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import mainlogo from "../../assets/img/banner.jpg";
import NewCibona from "../../assets/img/Cibona-New-logo.png";
import { Link } from "react-router-dom";
import AllEventCard from "../AllEvent/AllEventCard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsDot, BsFilterLeft } from "react-icons/bs";
import { IoFastFoodOutline, IoLocationOutline } from "react-icons/io5";
import { MdDateRange, MdSchedule } from "react-icons/md";
import { GiMoneyStack } from "react-icons/gi";
import { LuParkingCircle } from "react-icons/lu";
import { FaCar, FaSearch } from "react-icons/fa";
import CibonaZagreb from "../../assets/img/CIBONA-ZAGREB-logo.png";
import img1 from "../../assets/img/event-imgs/big-1.jpg";

function TeamPage() {
  const [allData, setAllData] = useState(false);
  const [seasonPassbtn, setSeasonPassbtn] = useState(false);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [startDate, setStartDate] = useState(tomorrow);
  const [endDate, setEndDate] = useState(tomorrow);

  const Breadcrumbs = ({ breadcrumbs }) => {
    return (
      <div className="breadcrumbs">
        {breadcrumbs.map((breadcrumb, index) => (
          <span key={index}>
            {index > 0 && <span className="breadcrumbs-separator">/</span>}
            {breadcrumb.link ? (
              <Link to={breadcrumb.link}>{breadcrumb.label}</Link>
            ) : (
              <span>{breadcrumb.label}</span>
            )}
          </span>
        ))}
      </div>
    );
  };

  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Teams", link: "#" },
    { label: "KK Cibona" },
  ];

  return (
    <>
      <Header />
      <div className="wrapper" id="otherPages">
        <div className="hero-banner-landing">
          <img className="landing-main-img" src={mainlogo} alt="" />
          <div className="league-page-search">
            <input
              id="outlined-basic"
              variant="outlined"
              className="league-page-search-input"
              fullWidth
              placeholder="Search"
              label="Search"
            />
            <div className="league-page-search-icon">
              <FaSearch />
            </div>
          </div>
          <div className="league-page-search-breadcrumb">
            {/* Home / Leagues / ABA League */}
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
          <div className="league-page-search-logo container">
            <img src={CibonaZagreb} alt="" className="team-logo" />
          </div>
          <div>
            {" "}
            <h2 className="container mt-3">
              <img src={NewCibona} alt="" /> <b>KK CIBONA ZAGREB</b>
            </h2>
            <p className="container">
              Košarkaški klub Cibona, commonly referred to as Cibona Zagreb or
              simply Cibona, is a men's professional basketball club based in
              Zagreb, Croatia. The club is a founding member and shareholder of
              the Adriatic Basketball Association, and competes in the Adriatic
              League and the Croatian League.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="d-flex flex-wrap gap-3">
            <button
              className="new-main-tab "
              onClick={() => [setAllData(true), setSeasonPassbtn(false)]}
            >
              Single Match Tickets
            </button>
            <button
              className="new-main-tab"
              onClick={() => [setSeasonPassbtn(true), setAllData(false)]}
            >
              Seasons Pass
            </button>
            <button className="new-main-tab">Bundle Packages</button>
            <button className="new-main-tab calendar-tab">Calendar</button>
            <button className="new-main-tab calendar-tab">About</button>
          </div>
          {allData === true ? (
            <>
              <div className="main-title position-relative mt-4">
                <h4 className="fs-2 fw-bold">Single Match Tickets</h4>
                <div className="d-flex justify-content-between">
                  <div className="d-flex gap-3">
                    <div className="form-control filter-team d-flex">
                      <MdDateRange className="icon-team" />
                      <DatePicker
                        //   showIcon
                        className="h_40 mx-1 form-control form-control-BR w-100"
                        selected={startDate}
                        dateFormat="dd MMM,yyyy"
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>
                    <div className="form-control filter-team d-flex">
                      <IoLocationOutline className="icon-team" />
                      <input
                        type="text"
                        className="h_40 form-control form-control-BR w-100"
                        name=""
                        id=""
                        placeholder="Location"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end gap-3">
                    <div className="form-control filter-team d-flex">
                      <BsFilterLeft className="icon-team" />
                      <select
                        name=""
                        id=""
                        className="h_40 form-control form-control-BR w-100"
                      >
                        <option value=""> Teams</option>
                      </select>
                    </div>
                    <div className="form-control filter-team d-flex">
                      <BsFilterLeft className="icon-team" />
                      <input
                        type="text"
                        className="h_40 form-control form-control-BR w-100"
                        name=""
                        id=""
                        placeholder="Calendar View"
                      />
                    </div>
                    <div className="form-control filter-team d-flex">
                      <BsFilterLeft className="icon-team" />
                      <select
                        name=""
                        id=""
                        className="h_40 form-control form-control-BR w-100"
                      >
                        <option value=""> Sort</option>
                      </select>
                      {/* <input
                    type="text"
                    className="h_40 form-control form-control-BR w-100"
                    name=""
                    id=""
                    placeholder="Sort"
                  /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="new-card-team d-flex flex-wrap mt-3">
                  <img src={img1} alt="" className="card-img-team" />
                  <div className="m-3 responsive-team-card">
                    <h3>Cibona vs Zadar</h3>
                    <span>29 Nov, 2024 • Fri, 5:00AM</span> <br />
                    <span>Dražen Petrović Basketball Hall • Zagreb, HR</span>
                  </div>
                  <button className="buybtn-team">Buy Tickets</button>
                </div>
                <div className="new-card-team d-flex flex-wrap mt-3">
                  <img src={img1} alt="" className="card-img-team" />
                  <div className="m-3 responsive-team-card">
                    <h3>Cibona vs Zadar</h3>
                    <span>29 Nov, 2024 • Fri, 5:00AM</span> <br />
                    <span>Dražen Petrović Basketball Hall • Zagreb, HR</span>
                  </div>
                  <button className="buybtn-team">Buy Tickets</button>
                </div>
                <div className="new-card-team d-flex flex-wrap mt-3">
                  <img src={img1} alt="" className="card-img-team" />
                  <div className="m-3 responsive-team-card">
                    <h3>Cibona vs Zadar</h3>
                    <span>29 Nov, 2024 • Fri, 5:00AM</span> <br />
                    <span>Dražen Petrović Basketball Hall • Zagreb, HR</span>
                  </div>
                  <button className="buybtn-team">Buy Tickets</button>
                </div>
                <div className="new-card-team d-flex flex-wrap mt-3">
                  <img src={img1} alt="" className="card-img-team" />
                  <div className="m-3 responsive-team-card">
                    <h3>Cibona vs Zadar</h3>
                    <span>29 Nov, 2024 • Fri, 5:00AM</span> <br />
                    <span>Dražen Petrović Basketball Hall • Zagreb, HR</span>
                  </div>
                  <button className="buybtn-team">Buy Tickets</button>
                </div>
                <div className="new-card-team d-flex flex-wrap mt-3">
                  <img src={img1} alt="" className="card-img-team" />
                  <div className="m-3 responsive-team-card">
                    <h3>Cibona vs Zadar</h3>
                    <span>29 Nov, 2024 • Fri, 5:00AM</span> <br />
                    <span>Dražen Petrović Basketball Hall • Zagreb, HR</span>
                  </div>
                  <button className="buybtn-team">Buy Tickets</button>
                </div>
              </div>
            </>
          ) : (
            <>
              {seasonPassbtn === true ? (
                <>
                  <div className="main-title position-relative mt-4">
                    <h4 className="fs-2 fw-bold">Seasons Passes</h4>
                    {/* <AllEventCard /> */}
                    <div
                      className="row"
                      data-ref="event-filter-content"
                      id="otherPages"
                    >
                      <div
                        className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mix arts concert workshops volunteer sports health_Wellness"
                        data-ref="mixitup-target"
                      >
                        <div className="main-card mt-4">
                          <div className="event-thumbnail">
                            <Link to={"#"} className="thumbnail-img">
                              <img src={img1} alt="" />
                            </Link>
                          </div>
                          <div className="event-content">
                            <Link to={"#"} className="event-title event-css">
                              Silver Tier
                            </Link>

                            <div className="publish-date">
                              <p className="season-details-team">
                                2 Events{" "}
                                <BsDot
                                  style={{ width: "23px", height: "23px" }}
                                />
                                £1.00
                                <br />{" "}
                                <IoFastFoodOutline
                                  style={{ width: "23px", height: "23px" }}
                                />{" "}
                                Food and drink included
                                <br />
                                <FaCar
                                  style={{ width: "23px", height: "23px" }}
                                />{" "}
                                Parking pass
                              </p>
                            </div>
                            <button className="main-btn btn-hover w-100 mt-4">
                              View Details{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mix arts concert workshops volunteer sports health_Wellness"
                        data-ref="mixitup-target"
                      >
                        <div className="main-card mt-4">
                          <div className="event-thumbnail">
                            <Link to={"#"} className="thumbnail-img">
                              <img src={img1} alt="" />
                            </Link>
                          </div>
                          <div className="event-content">
                            <Link to={"#"} className="event-title event-css">
                            Gold Tier
                            </Link>

                            <div className="publish-date">
                              <p className="season-details-team">
                                2 Events{" "}
                                <BsDot
                                  style={{ width: "23px", height: "23px" }}
                                />
                                £2.00
                                <br />{" "}
                                <IoFastFoodOutline
                                  style={{ width: "23px", height: "23px" }}
                                />{" "}
                                Food and drink included
                                <br />
                                <FaCar
                                  style={{ width: "23px", height: "23px" }}
                                />{" "}
                                Parking pass
                              </p>
                            </div>
                            <button className="main-btn btn-hover w-100 mt-4">
                              View Details{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5">
                      <h6 className="fs-3 fw-bold">Silver Tier</h6>
                      <span className="fs-5 fw-bold">Schedule (2 Events)</span>
                      <div className="row">
                        <div className="col-md-8">
                          <div className="">
                            <div className="new-card-team d-flex flex-wrap mt-3">
                              <img
                                src={img1}
                                alt=""
                                className="card-img-team"
                              />
                              <div className="m-3 responsive-team-card">
                                <h6 className="fs-4 fw-bold">
                                  Cibona vs Zadar
                                </h6>
                                <span>29 Nov, 2024 • Fri, 5:00AM</span> <br />
                                <span>
                                  Dražen Petrović Basketball Hall • Zagreb, HR
                                </span>
                              </div>
                            </div>
                            <div className="new-card-team d-flex flex-wrap mt-3">
                              <img
                                src={img1}
                                alt=""
                                className="card-img-team"
                              />
                              <div className="m-3 responsive-team-card">
                                <h6 className="fs-4 fw-bold">
                                  Cibona vs Zadar
                                </h6>
                                <span>29 Nov, 2024 • Fri, 5:00AM</span> <br />
                                <span>
                                  Dražen Petrović Basketball Hall • Zagreb, HR
                                </span>
                              </div>
                            </div>
                            <div className="new-card-team d-flex flex-wrap mt-3">
                              <img
                                src={img1}
                                alt=""
                                className="card-img-team"
                              />
                              <div className="m-3 responsive-team-card">
                                <h6 className="fs-4 fw-bold">
                                  Cibona vs Zadar
                                </h6>
                                <span>29 Nov, 2024 • Fri, 5:00AM</span> <br />
                                <span>
                                  Dražen Petrović Basketball Hall • Zagreb, HR
                                </span>
                              </div>
                            </div>
                            <div className="new-card-team d-flex flex-wrap mt-3">
                              <img
                                src={img1}
                                alt=""
                                className="card-img-team"
                              />
                              <div className="m-3 responsive-team-card">
                                <h6 className="fs-4 fw-bold">
                                  Cibona vs Zadar
                                </h6>
                                <span>29 Nov, 2024 • Fri, 5:00AM</span> <br />
                                <span>
                                  Dražen Petrović Basketball Hall • Zagreb, HR
                                </span>
                              </div>
                            </div>
                            <div className="new-card-team d-flex flex-wrap mt-3">
                              <img
                                src={img1}
                                alt=""
                                className="card-img-team"
                              />
                              <div className="m-3 responsive-team-card">
                                <h6 className="fs-4 fw-bold">
                                  Cibona vs Zadar
                                </h6>
                                <span>29 Nov, 2024 • Fri, 5:00AM</span> <br />
                                <span>
                                  Dražen Petrović Basketball Hall • Zagreb, HR
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="main-card mt-3 p-4">
                            <span className="fs-5 fw-bold">
                              Silver Tier Details
                            </span>
                            <div className="event-dt-right-group mt-5">
                              {" "}
                              <div className="event-dt-right-icon">
                                <MdSchedule />
                              </div>
                              <div className="event-dt-right-content">
                                <h4>Schedule</h4>
                                <h5>2 Events</h5>
                              </div>
                            </div>
                            <div className="event-dt-right-group mt-5">
                              {" "}
                              <div className="event-dt-right-icon">
                                <MdSchedule />
                              </div>
                              <div className="event-dt-right-content">
                                <h4>Amenities</h4>
                                <h5>Food and drink included</h5>
                              </div>
                            </div>
                            <div className="event-dt-right-group mt-5">
                              {" "}
                              <div className="event-dt-right-icon">
                                <LuParkingCircle />
                              </div>
                              <div className="event-dt-right-content">
                                <h4>Parking</h4>
                                <h5>Complimentary parking</h5>
                              </div>
                            </div>
                            <div className="event-dt-right-group mt-5">
                              {" "}
                              <div className="event-dt-right-icon">
                                <GiMoneyStack />
                              </div>
                              <div className="event-dt-right-content">
                                <h4>Price</h4>
                                <h5>€1.00</h5>
                              </div>
                            </div>
                            <div className="booking-btn">
                              <button className="main-btn btn-hover w-100">
                                Book Now
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="main-title position-relative mt-4">
                    <h4 className="fs-2 fw-bold">Upcoming Games</h4>
                    <Link to="#" className="view-all-link">
                      View More
                    </Link>
                  </div>
                  <AllEventCard />
                  <div className="main-title position-relative mt-5">
                    <h4 className="fs-2 fw-bold">All Games</h4>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex gap-3">
                        <div className="form-control filter-team d-flex">
                          <MdDateRange className="icon-team" />
                          <DatePicker
                            //   showIcon
                            className="h_40 mx-1 form-control form-control-BR w-100"
                            selected={startDate}
                            dateFormat="dd MMM,yyyy"
                            onChange={(date) => setStartDate(date)}
                          />
                        </div>
                        <div className="form-control filter-team d-flex">
                          <IoLocationOutline className="icon-team" />
                          <input
                            type="text"
                            className="h_40 form-control form-control-BR w-100"
                            name=""
                            id=""
                            placeholder="Location"
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-end gap-3">
                        <div className="form-control filter-team d-flex">
                          <BsFilterLeft className="icon-team" />
                          <select
                            name=""
                            id=""
                            className="h_40 form-control form-control-BR w-100"
                          >
                            <option value=""> Teams</option>
                          </select>
                        </div>
                        <div className="form-control filter-team d-flex">
                          <BsFilterLeft className="icon-team" />
                          <input
                            type="text"
                            className="h_40 form-control form-control-BR w-100"
                            name=""
                            id=""
                            placeholder="Calendar View"
                          />
                        </div>
                        <div className="form-control filter-team d-flex">
                          <BsFilterLeft className="icon-team" />
                          <select
                            name=""
                            id=""
                            className="h_40 form-control form-control-BR w-100"
                          >
                            <option value=""> Sort</option>
                          </select>
                          {/* <input
                    type="text"
                    className="h_40 form-control form-control-BR w-100"
                    name=""
                    id=""
                    placeholder="Sort"
                  /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="new-card-team d-flex flex-wrap mt-3">
                      <img src={img1} alt="" className="card-img-team" />
                      <div className="m-3 responsive-team-card">
                        <h3>Cibona vs Zadar</h3>
                        <span>29 Nov, 2024 • Fri, 5:00AM</span> <br />
                        <span>
                          Dražen Petrović Basketball Hall • Zagreb, HR
                        </span>
                      </div>
                      <button className="buybtn-team">Buy Tickets</button>
                    </div>
                    <div className="new-card-team d-flex flex-wrap mt-3">
                      <img src={img1} alt="" className="card-img-team" />
                      <div className="m-3 responsive-team-card">
                        <h3>Cibona vs Zadar</h3>
                        <span>29 Nov, 2024 • Fri, 5:00AM</span> <br />
                        <span>
                          Dražen Petrović Basketball Hall • Zagreb, HR
                        </span>
                      </div>
                      <button className="buybtn-team">Buy Tickets</button>
                    </div>
                    <div className="new-card-team d-flex flex-wrap mt-3">
                      <img src={img1} alt="" className="card-img-team" />
                      <div className="m-3 responsive-team-card">
                        <h3>Cibona vs Zadar</h3>
                        <span>29 Nov, 2024 • Fri, 5:00AM</span> <br />
                        <span>
                          Dražen Petrović Basketball Hall • Zagreb, HR
                        </span>
                      </div>
                      <button className="buybtn-team">Buy Tickets</button>
                    </div>
                    <div className="new-card-team d-flex flex-wrap mt-3">
                      <img src={img1} alt="" className="card-img-team" />
                      <div className="m-3 responsive-team-card">
                        <h3>Cibona vs Zadar</h3>
                        <span>29 Nov, 2024 • Fri, 5:00AM</span> <br />
                        <span>
                          Dražen Petrović Basketball Hall • Zagreb, HR
                        </span>
                      </div>
                      <button className="buybtn-team">Buy Tickets</button>
                    </div>
                    <div className="new-card-team d-flex flex-wrap mt-3">
                      <img src={img1} alt="" className="card-img-team" />
                      <div className="m-3 responsive-team-card">
                        <h3>Cibona vs Zadar</h3>
                        <span>29 Nov, 2024 • Fri, 5:00AM</span> <br />
                        <span>
                          Dražen Petrović Basketball Hall • Zagreb, HR
                        </span>
                      </div>
                      <button className="buybtn-team">Buy Tickets</button>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </>
  );
}

export default TeamPage;
