import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { toast } from "react-toastify";

function ResellCheckout(props) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openInfo, setOpenInfo] = useState(false);
  const handleCloseInfo = () => {
    setOpenInfo(false);
  };
  const [text_ph_enterEmailId, setText_ph_enterEmailId] = useState(
    "Enter Your Email ID"
  );
  const [text_paynow, setText_paynow] = useState("Pay now");
  const [text_cancel, setText_cancel] = useState("Cancel");

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          let payid = paymentIntent.id;
          // buyTicketHandle(payid);
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: "http://localhost:3000/resell-payment",
        return_url: "https://www.tick8.online/resell-payment",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
      toast.error(error.message);
    } else {
      setMessage("An unexpected error occurred.");
      toast.error("An unexpected error occurred");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  const handleTestBuy = () => {
    // setIsLoading(true);
    let text = props.clientSecret;
    const myArray = text.split("_secret_");
    console.log(myArray[0]);
    navigate("/resell-payment", {
      state: { paymentid: myArray[0] },
    });
    // setTimeout(()=>{
    //   navigate("/payment-status", {
    //     state: { paymentid: myArray[0] },
    //   });
    // },2000)
  };
  return (
    <>
      <center>
        <div className="w-50">
          <div
            id="payment-form"
            // onSubmit={handleSubmit}
          >
            {/* <LinkAuthenticationElement
          id="link-authentication-element"
          onChange={(e) => [setEmail(e.target.value),console.log(e)]}
        /> */}
            <h4 className="text-start">Confirmation Email ID</h4>
            <input
              type="email"
              className="form-control h_50 mb-3"
              placeholder={text_ph_enterEmailId}
              id="link-authentication-element"
              defaultValue={sessionStorage.getItem("useremail")}
              onChange={(e) => [setEmail(e.target.value), console.log(e)]}
            />
            <PaymentElement
              id="payment-element"
              options={paymentElementOptions}
            />
            <button
              disabled={isLoading || !stripe || !elements}
              id="submit"
              className="payNowButton mt-2"
              onClick={handleSubmit}
            >
              <span id="button-text">
                {isLoading ? (
                  <div className="spinner" id="spinner"></div>
                ) : (
                  <>{text_paynow}</>
                )}
              </span>
            </button>
            <button
              className="payNowButtonCancel mt-2"
              onClick={() => setOpenInfo(true)}
            >
              <span id="button-text">{text_cancel}</span>
            </button>
            {/* Show any error or success messages */}
            {/* {message && (
              <div id="payment-message" style={{ color: "red" }}>
                {message}
              </div>
            )} */}
          </div>
          <Dialog
            fullScreen={fullScreen}
            open={openInfo}
            onClose={handleCloseInfo}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title" className="text-center">
              {/* <BiLogIn className="Email-icon" /> */}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <h3 className="text-center">
                  Are you sure you want to cancel transaction?
                </h3>
                {/* <h5 className="text-center">
              We have sent you a link on your registered email or phone no. to
              verify your Account.
            </h5>
            <h5 className="text-center">
              Please check your Spam or Junk folder.
            </h5> */}
                <br />
                {/* <center>
              <Link onClick={() => []} style={{ cursor: "pointer" }}>
                <u>Resend verification link</u>
              </Link>
            </center> */}
              </DialogContentText>
              <Divider />
            </DialogContent>
            <>
              <div className="d-flex justify-content-center mb-3 gap-4">
                <button className="cancelBtn " onClick={handleCloseInfo}>
                  No
                </button>
                <button
                  className="yesBtn "
                  // onClick={() => navigate(`/resell-event-details/${props.eID}`)}
                >
                  Yes
                </button>
              </div>
            </>
          </Dialog>
        </div>
      </center>
    </>
  );
}

export default ResellCheckout;
