import { Dialog, DialogContent, DialogContentText, DialogTitle, Divider, useMediaQuery, useTheme } from '@mui/material';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GlobalConstants } from "../../utils/GlobalConst";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function BundleCheckOut(props) {
    const [isLoading, setIsLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [openInfo, setOpenInfo] = useState(false);
    const handleCloseInfo = () => {
      setOpenInfo(false);
    };
    const [message, setMessage] = useState(null);
  
    const [text_ph_enterEmailId, setText_ph_enterEmailId] = useState(
      "Enter Your Email ID"
    );
    const [text_paynow, setText_paynow] = useState("Pay now");
    const [text_cancel, setText_cancel] = useState("Cancel");
  
    const paymentElementOptions = {
      layout: "tabs",
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
  
      setIsLoading(true);
  
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: "https://www.tick8.online/bundleevent/paymentstatus",
  
          // return_url: "http://localhost:3000/bundleevent/paymentstatus",
        },
      });
  
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      toast.error(error.message);

      } else {
        setMessage("An unexpected error occurred.");
      toast.error("An unexpected error occurred");

      }
  
      setIsLoading(false);
    };
     const handleTestBuy = () => {
       // setIsLoading(true);
       let text = props.clientSecret;
       const myArray = text.split("_secret_");
       console.log(myArray[0]);
       navigate("/bundleevent/paymentstatus", {
         state: { paymentid: myArray[0] },
       });
       // setTimeout(()=>{
       //   navigate("/payment-status", {
       //     state: { paymentid: myArray[0] },
       //   });
       // },2000)
     };
     useEffect(() => {
      try {
        SetLanguageText();
      } catch (err) {
        console.log("" + err);
      }
    }, []);
    const SetLanguageText = () => {
      try {
        var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
        var doc = new dom().parseFromString(xml);
        setText_ph_enterEmailId(
          xpath.select1("/resources/string[@name='text_ph_enterEmailId']", doc)
            .firstChild.data
        );
        setText_paynow(
          xpath.select1("/resources/string[@name='text_paynow']", doc).firstChild
            .data
        );
        setText_cancel(
          xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
            .data
        );
      } catch (ert) {}
    };
  return (
    <>
      <div id="payment-form">
        {/* <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => [setEmail(e.target.value),console.log(e)]}
      /> */}
        <p>Confirmation Email ID</p>
        <input
          type="email"
          className="form-control mb-3"
          placeholder={text_ph_enterEmailId}
          id="link-authentication-element"
          defaultValue={sessionStorage.getItem("useremail")}
        />
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button
          disabled={isLoading || !stripe || !elements}
          id="submit"
          className="payNowButton mt-2"
          onClick={handleSubmit}
        >
          <span id="button-text">
            {isLoading ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              <> {text_paynow}</>
            )}
          </span>
        </button>
        <button
          className="payNowButtonCancel mt-2"
          onClick={() => setOpenInfo(true)}
        >
          <span id="button-text">{text_cancel}</span>
        </button>
        {/* Show any error or success messages */}
        {/* {message && <div id="payment-message">{message}</div>} */}
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={openInfo}
        onClose={handleCloseInfo}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-center">
          {/* <BiLogIn className="Email-icon" /> */}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h3 className="text-center">
              Are you sure you want to cancel transaction?
            </h3>
            {/* <h5 className="text-center">
            We have sent you a link on your registered email or phone no. to
            verify your Account.
          </h5>
          <h5 className="text-center">
            Please check your Spam or Junk folder.
          </h5> */}
            <br />
            {/* <center>
            <Link onClick={() => []} style={{ cursor: "pointer" }}>
              <u>Resend verification link</u>
            </Link>
          </center> */}
          </DialogContentText>
          <Divider />
        </DialogContent>
        <>
          <div className="d-flex justify-content-center mb-3 gap-4">
            <button className="cancelBtn " onClick={handleCloseInfo}>
              No
            </button>
            <button className="yesBtn" onClick={() => window.location.reload()}>
              Yes
            </button>
          </div>
        </>
      </Dialog>
    </>
  );
}

export default BundleCheckOut;
