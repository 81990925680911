import React, { useState, useEffect } from "react";
import "../../component/Login/Login.css";
import { Link, useNavigate } from "react-router-dom";
import mainlogo from "../../assets/img/tiq8-Logo-white.png";
import { FaExternalLinkAlt } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import axios from "axios";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FcFeedback } from "react-icons/fc";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import moment from "moment";
import { EMAIL_REGEX } from "../../utils/Validation";
import PhoneInput from "react-phone-input-2";

const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function Support() {
  const [emailMobile, setEmailMobile] = useState("");
  const [ErremailMobile, setErrEmailMobile] = useState("");
  const [helperr, setHelperr] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openloginEmailInfo, setOpenloginEmailInfo] = useState(false);
  const [sendLinkbtn, setSendLinkBtn] = useState(false);

  // language variable
  const [text_backtosignin, setText_backtosignin] = useState("Back to sign in");
  const [text_help, setText_help] = useState("How Can We Help");
  const [text_rightsReserved, setText_rightsReserved] = useState(
    "All rights reserved"
  );
  const [login_title, setLogin_title] = useState(
    "The Easiest Way to Create Events and Sell More Tickets Online"
  );

  // new
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [PhoneNo, setPhoneNo] = useState("");
  const [Message, setMessage] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [messageErr, setMessageErr] = useState("");
  const [text_submit, setText_submit] = useState("Submit");
  const [text_message, setText_message] = useState("Message");
  const [text_phone, setText_phone] = useState("Phone");
  const [text_ph_email, setText_ph_email] = useState("Enter Email");
  const [text_email, setText_email] = useState("Email");
  const [text_ph_lastName, setText_ph_lastName] = useState("Enter Last Name");
  const [text_ph_firstName, setText_ph_firstName] =
    useState("Enter First Name");
  const [text_lastname, setText_lastname] = useState("Last Name");
  const [text_firstname, setText_firstname] = useState("First Name");
  const [text_noticecontactus, setText_noticecontactus] = useState(
    "Have any questions? We'd love to hear from you."
  );
  const [text_contactus, setText_contactus] = useState("Contact Us");
  const [text_home, setText_home] = useState("Home");
  const [text_err_email, setText_err_email] = useState("Please Enter Email");
  const [text_err_valid_email, setText_err_valid_email] = useState(
    "Please Enter Valid Email"
  );
  const [text_entermessage, setText_entermessage] = useState(
    "Please Enter Message"
  );
  const [text_having_trouble, setText_having_trouble] = useState(
    "Having Trouble sign in"
  );
  const [text_subject, setText_subject] = useState("Subject");
  const [text_enter_issue, setText_enter_issue] = useState("Enter Issue");

  const navigate = useNavigate();

  const ContactValidationHandler = () => {
    var isValidcontact = true;

    if (!Email) {
      isValidcontact = false;
      setEmailErr(<>*{text_err_email}!</>);
    }
    if (Email !== "" && EMAIL_REGEX.test(Email) === false) {
      isValidcontact = false;
      setEmailErr(<>*{text_err_valid_email}!</>);
    }
    if (!Message) {
      isValidcontact = false;
      setMessageErr(<>*{text_entermessage}!</>);
    }
    // setLoginBtn(false);
    return isValidcontact;
  };

  const ContactHandle = () => {
    if (ContactValidationHandler()) {
      var API_URL = API_DOMAIN + "contact/contactus";
      // let headerConfig = {
      //   headers: {
      //     authorization: "bearer " + sessionStorage.getItem("token"),
      //   },
      // };
      var data = {
        firstname: FirstName,
        lastname: LastName,
        email: Email,
        phone: PhoneNo,
        msg: Message,
      };
      axios
        .post(API_URL, data)
        .then(function (response) {
          console.log("response***", response);
          toast.success(response?.data?.message, {
            autoClose: 2000,
            theme: "light",
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        })
        .catch(function (error) {
          console.log("catch block err***", error.response.data);
          if (error.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else {
            // setForgoterr(error.response.data.message);
          }
        })
        .then(function () {});
    }
  };

  // const resendValidation = () => {
  //   var isValidfor = true;
  //   if (emailMobile === "") {
  //     isValidfor = false;
  //     setErrEmailMobile(<>{text_err_emailorphone}</>);
  //   }
  //   setSendLinkBtn(false);
  //   return isValidfor;
  // };

  // const SendVerificationEmail = () => {
  //   if (resendValidation()) {
  //     setSendLinkBtn(true);
  //     var API_URL = API_DOMAIN + "user/resendverifylink";
  //     let headerConfig = {
  //       headers: {
  //         accept: "application/json",
  //       },
  //     };
  //     var data = {
  //       username: emailMobile,
  //     };
  //     axios
  //       .post(API_URL, data, headerConfig)
  //       .then(function (response) {
  //         console.log("response***", response);
  //         if (response.data.status === true) {
  //           setSendLinkBtn(false);
  //           setOpenloginEmailInfo(true);
  //           // toast.success(response?.data?.message);
  //         }
  //       })
  //       .catch(function (error) {
  //         setHelperr(error?.response?.data?.message);
  //         console.log("catch block err***", error.response.data);
  //         setSendLinkBtn(false);
  //         if (error.response.status === 427) {
  //           sessionStorage.clear();
  //           localStorage.clear();
  //           setTimeout(() => {
  //             navigate("/");
  //           }, 1000);
  //         } else {
  //           setHelperr(error.response.data.message);
  //         }
  //       })
  //       .then(function () {});
  //   }
  // };

  const keyHandler = (e) => {
    if (e.key === "Enter") {
      if (sendLinkbtn) {
        return;
      }
      ContactHandle();
    }
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_backtosignin(
        xpath.select1("/resources/string[@name='text_backtosignin']", doc)
          .firstChild.data
      );
      setText_help(
        xpath.select1("/resources/string[@name='text_help']", doc).firstChild
          .data
      );
      setText_rightsReserved(
        xpath.select1("/resources/string[@name='text_rightsReserved']", doc)
          .firstChild.data
      );

      setLogin_title(
        xpath.select1("/resources/string[@name='login_title']", doc).firstChild
          .data
      );
      setText_submit(
        xpath.select1("/resources/string[@name='text_submit']", doc).firstChild
          .data
      );
      setText_message(
        xpath.select1("/resources/string[@name='text_message']", doc).firstChild
          .data
      );
      setText_phone(
        xpath.select1("/resources/string[@name='text_phone']", doc).firstChild
          .data
      );
      setText_ph_email(
        xpath.select1("/resources/string[@name='text_ph_email']", doc)
          .firstChild.data
      );
      setText_email(
        xpath.select1("/resources/string[@name='text_email']", doc).firstChild
          .data
      );
      setText_ph_lastName(
        xpath.select1("/resources/string[@name='text_ph_lastName']", doc)
          .firstChild.data
      );
      setText_ph_firstName(
        xpath.select1("/resources/string[@name='text_ph_firstName']", doc)
          .firstChild.data
      );
      setText_lastname(
        xpath.select1("/resources/string[@name='text_lastname']", doc)
          .firstChild.data
      );
      setText_firstname(
        xpath.select1("/resources/string[@name='text_firstname']", doc)
          .firstChild.data
      );
      setText_noticecontactus(
        xpath.select1("/resources/string[@name='text_noticecontactus']", doc)
          .firstChild.data
      );
      setText_contactus(
        xpath.select1("/resources/string[@name='text_contactus']", doc)
          .firstChild.data
      );
      setText_err_email(
        xpath.select1("/resources/string[@name='text_err_email']", doc)
          .firstChild.data
      );
      setText_err_valid_email(
        xpath.select1("/resources/string[@name='text_err_valid_email']", doc)
          .firstChild.data
      );
      setText_entermessage(
        xpath.select1("/resources/string[@name='text_entermessage']", doc)
          .firstChild.data
      );
      setText_having_trouble(
        xpath.select1("/resources/string[@name='text_having_trouble']", doc)
          .firstChild.data
      );
      setText_subject(
        xpath.select1("/resources/string[@name='text_subject']", doc)
          .firstChild.data
      );
      setText_enter_issue(
        xpath.select1("/resources/string[@name='text_enter_issue']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      {" "}
      <div className="form-wrapper" id="otherPages">
        <div className="app-form">
          <div className="app-form-sidebar">
            <Link to="/">
              <div className="sidebar-sign-logo">
                <img src={mainlogo} alt="" className="side-logo-outerPage" />
              </div>
            </Link>
            <div className="sign_sidebar_text">
              <h1>{login_title}</h1>
            </div>
          </div>
          <div className="app-form-content">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 col-md-10">
                  <div className="app-top-items">
                    <Link to="/">
                      <div className="sign-logo" id="logo">
                        <img src={mainlogo} alt="" />
                        <img className="logo-inverse" src={mainlogo} alt="" />
                      </div>
                    </Link>
                    <div className="app-top-right-link">
                      <Link className="sidebar-register-link" to="/signin">
                        <IoArrowBackCircleOutline className="fp_backsign" />{" "}
                        {text_backtosignin}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-7 mt-5">
                  <div className="registration">
                    <div onKeyPress={keyHandler}>
                      <h2 className="registration-title">
                        {text_having_trouble}?
                      </h2>
                      <br />
                      {/* <h4 className="HeadlineText">{text_verify_head}</h4> */}
                      <div className="order-lg-2">
                        <div className="contact-form bp-form pb-5 ">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group profile-night-form mt-4">
                                <label className="form-label">
                                  {text_firstname}
                                </label>
                                <input
                                  className="form-control h_50"
                                  type="text"
                                  placeholder={text_ph_firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group profile-night-form mt-4">
                                <label className="form-label">
                                  {text_lastname}
                                </label>
                                <input
                                  className="form-control h_50"
                                  type="text"
                                  placeholder={text_ph_lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group profile-night-form mt-4">
                                <label className="form-label">
                                  {text_email}
                                  <span className="starsty">*</span>
                                </label>
                                <input
                                  className="form-control h_50"
                                  type="Email"
                                  placeholder={text_ph_email}
                                  onChange={(e) => [
                                    setEmail(e.target.value),
                                    setEmailErr(""),
                                  ]}
                                />
                              </div>
                              <span className="starsty">{emailErr}</span>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group profile-night-form mt-4">
                                <label className="form-label">
                                  {text_phone}
                                </label>
                                <PhoneInput
                                  country={"us"}
                                  inputClass="Register-input"
                                  onChange={(e) => setPhoneNo(e)}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group profile-night-form mt-4">
                                <label className="form-label">
                                  {text_subject} ({text_enter_issue})
                                  <span className="starsty">*</span>
                                </label>
                                <textarea
                                  className="form-textarea"
                                  placeholder={text_enter_issue}
                                  onChange={(e) => [
                                    setMessage(e.target.value),
                                    setMessageErr(""),
                                  ]}
                                ></textarea>
                              </div>
                              <span className="starsty">{messageErr}</span>
                            </div>
                            <div className="col-md-12">
                              <div className="text-center mt-4">
                                <button
                                  className="main-btn btn-hover h_50 w-100"
                                  type="submit"
                                  onClick={ContactHandle}
                                >
                                  {text_submit}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="new-sign-link">
                <Link className="signup-link" to="/signin">
                  {text_backtosignin}
                </Link>
              </div>
            </div>
            <div className="copyright-footer">
              © {moment(new Date()).format("YYYY")}, TiQ8. {text_rightsReserved}
              .
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Support;
