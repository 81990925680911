import React, { useState, useEffect } from "react";
import "../PrivacyPolicy/PrivacyPolicy.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import { GlobalConstants } from "../../utils/GlobalConst";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function PrivacyPolicy() {
  const [text_home, setText_home] = useState("Home");
  const [text_privacypolicy, setText_privacypolicy] =
    useState("Privacy Policy");
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_privacypolicy(
        xpath.select1("/resources/string[@name='text_privacypolicy']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div
        className="Create-event-wrapper eventdetail-nightmod"
        id="otherPages"
      >
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_privacypolicy}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container termsbox mt-4 mb-4">
          <div className="termsheadbox p-3">
            <h3 className="text-center privacy-text mt-4 text-white">
              {" "}
              {text_privacypolicy}
            </h3>
            <h4 className="text-white">Last Updated: 15 December 2023</h4>
          </div>
          <h4 className="privacy-body-text mt-4">
            Welcome to Tick8.online’s Privacy Policy
          </h4>
          <p>
            Welcome to the https://www.tick8.online/ website. tick8.online ("We," "Us,"
            or "Our") has created this Privacy Policy for the benefit of the
            users ("You" or "Your") of our tick8.online website, to demonstrate our
            serious commitment to protecting your privacy. This Privacy Policy
            applies to information about third-party individuals collected by us
            from Our Site users. This Privacy Policy explains how we collect,
            use, disclose, and protect your personal information when you use
            our services. By accessing or using our platform, you agree to the
            terms outlined in this Privacy Policy.
          </p>
          <h4 className="privacy-body-text ">
            WHAT INFORMATION WE COLLECT AND HOW WE DO IT
          </h4>
          <p>Information can generally be collected in two ways: </p>
          <p>
            (1) Information that is knowingly and voluntarily input into Our
            system directly by You in response to particular prompts or fields,
            or that is otherwise knowingly and voluntarily provided by You (such
            as in an email)
          </p>
          <p>
            (2) Information that is collected automatically when you use the
            Site.
          </p>
          <h4 className="privacy-body-text">
            What personal information do we collect?
          </h4>
          <p>
            We may collect personal information including your name, gender,
            date of birth, address, telephone number, mobile number and email.
            We do not retain your financial information or credit card numbers
            used to purchase tickets. We will not email requesting financial
            information, as this does not comply with our privacy policy. If you
            receive a request to provide sensitive personal information (such as
            credit card details) in an email, do not provide this information
            because the request may be fraudulent. You can help us identify
            attempts at fraud- if anything suspicious occurs, please contact us.{" "}
          </p>
          <h4 className="privacy-body-text ">
            Why do we collect your personal information?
          </h4>
          <p>
            We may collect your personal information when required by law but
            generally we collect personal information from you (or about you) to
            allow us to: supply you with tailored service offerings that may
            benefit you, communicate more effectively with you, ensure your
            experience with us is a positive one and notify you about our new
            service offerings, discounts, promotions or upcoming events.
            Personal information collected or received by us will only be used
            for the stated purpose for which it was provided.
          </p>
          <h4 className="privacy-body-text">
            When and how do we collect your personal information?
          </h4>
          <p>
            We collect most personal information directly from you when you
            consent to communications from us. Your consent may be express (e.g.
            you agree to the use of your information by ticking a box) or
            implied by an action you take or do not take (i.e. because you have
            agreed to terms and conditions that contain information about the
            use or disclosure of your information). You provide us your
            information when you purchase (or enquire to purchase) products or
            services from us, you ask to find out more about our website or
            services, you make a refund, return or other claim, you use our
            website, or use our services at an event.
          </p>
          <p>
            We may also collect information about how you use our website(s) to
            help us better serve you. For example, we may do this when you click
            on a link from our website(s). This information is derived from
            cookies that identify the computer you used to access our
            website(s), and the cookies collect your server address; your top
            level domain name the time and date of your visit to the website;
            the pages and documents you have accessed or viewed and the type of
            browser you are using. When we use cookies and other tracking
            technologies to collect your personal and non-personally
            identifiable information we are collecting this information to
            better understand your browsing and purchasing behaviour; analyse
            and track website usage data; determine the popularity of certain
            content; deliver advertising and content targeted to your interest
            on our website; better understand your online activity; improve our
            website and your online experience.
          </p>
          <h4 className="privacy-body-text">THIRD-PARTY LINKS </h4>
          <p>
            The Site may contain links to websites owned and operated by third
            parties. tick8.online Privacy Policy applies only to Our Site. We do not
            exercise privacy control over the sites which may be linked from or
            to the tick8.online Site.
          </p>
          <h4 className="privacy-body-text">CHANGES TO THIS POLICY </h4>
          <p>
            Please review this Privacy Policy periodically as we may update it
            from time to time. When we make changes, we will revise the "Last
            Updated" date at the top of the Privacy Policy and post the changed
            policy on the Site. Your provision of any information on or through
            the Site following any such change will constitute your consent to
            the collection, disclosure and use of such information in accordance
            with the changed policy.{" "}
          </p>
          <h4 className="privacy-body-text">Contact Information </h4>
          <p>
            If you have any questions about this Privacy Policy, the practices
            of this site, or your dealings with this site, please contact us at:
          </p>
          <p>
            Contact Info:{" "}
            <Link to="mailto:support@tiq8.com">support@tiq8.com</Link>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
