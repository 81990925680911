import React, { useState, useEffect } from "react";
import "../PageNotFound/PageNotFound.css";
import { FcHome } from "react-icons/fc";
import { Link } from "react-router-dom";
import { GlobalConstants } from "../../utils/GlobalConst";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function PageNotFound() {
  const[text_home,setText_home] = useState("Home");
 const[text_oops,setText_oops] = useState("Oops!");
 const[text_notFound,setText_notfound] = useState("PAGE NOT FOUND");
const[text_notFound_msg,setText_notFound_msg] = useState("The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.");
const[text_error,setText_error] = useState("Error");
const[text_backtohome,setText_backtohome] = useState("Back to home");
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc)
          .firstChild.data
      );
      setText_oops(
        xpath.select1("/resources/string[@name='text_oops']", doc)
          .firstChild.data
      );
      setText_notfound(
        xpath.select1("/resources/string[@name='text_notFound']", doc)
          .firstChild.data
      );
      setText_notFound_msg(
        xpath.select1("/resources/string[@name='text_notFound_msg']", doc)
          .firstChild.data
      );
      setText_error(
        xpath.select1("/resources/string[@name='text_error']", doc)
          .firstChild.data
      );
      setText_backtohome(
        xpath.select1("/resources/string[@name='text_backtohome']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <div className="wrapper">
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_error} 404
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="event-dt-block p-80">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="error-404-content text-center">
                  <h2>404</h2>
                  <h4>{text_oops}{text_notFound}</h4>
                  <p>
                   {text_notFound_msg}
                  </p>
                  <Link to="/" className="main-btn btn-hover h_50">
                    <FcHome className="homeicon"/> {" "}
                    {text_backtohome}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageNotFound;
