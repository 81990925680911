import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import "../LeagueCreate/LeagueCreate.css";
import mainlogo from "../../assets/img/banner.jpg";
import team_icon1 from "../../assets/TeamIcon/team-1.png";
import team_icon2 from "../../assets/TeamIcon/team-2.png";
// import team_icon3 from "../../assets/TeamIcon/team-3.png";
import team_icon4 from "../../assets/TeamIcon/team-4.png";
import team_icon5 from "../../assets/TeamIcon/team-5.png";
import NewCibona from "../../assets/img/Cibona-New-logo.png";
import { MdDateRange } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { BsFilterLeft } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Footer from "../Footer/Footer";
import Abaleague from "../../assets/img/aba-league-logo.png";
import img1 from "../../assets/img/event-imgs/big-1.jpg";
import { FaXTwitter } from "react-icons/fa6";
import {
  FaFacebookSquare,
  FaYoutube,
  FaInstagram,
  FaTiktok,
  FaLink,
} from "react-icons/fa";
function LeaguePage() {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [startDate, setStartDate] = useState(tomorrow);
  // const [LandingPageData, setLandingPageData] = useState([]);
  const [aboutContainer, setAboutContainer] = useState(false);
  const handleClickAbout = () => {
    setAboutContainer(!aboutContainer);
  };
  const team_name = [
    { image: team_icon2, name: "KK Budcnost VOLI" },
    { image: team_icon4, name: "KK Borac Mozzart" },
    { image: team_icon5, name: "KK Cedvita Olimpija" },
    { image: team_icon1, name: "KK Cibona" },
    { image: team_icon2, name: "KK Budcnost VOLI" },
    { image: team_icon5, name: "KK Cedvita Olimpija" },
    { image: team_icon1, name: "KK Cibona" },
  ];
  const Breadcrumbs = ({ breadcrumbs }) => {
    return (
      <div className="breadcrumbs">
        {breadcrumbs.map((breadcrumb, index) => (
          <span key={index}>
            {index > 0 && <span className="breadcrumbs-separator">/</span>}
            {breadcrumb.link ? (
              <Link to={breadcrumb.link}>{breadcrumb.label}</Link>
            ) : (
              <span>{breadcrumb.label}</span>
            )}
          </span>
        ))}
      </div>
    );
  };
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Leagues", link: "#" },
    { label: "ABA League" },
  ];

  return (
    <>
      <Header />
      <div className="wrapper" id="otherPages">
        {/* <div className="leaguepage"></div> */}
        <div className="hero-banner-landing">
          <img className="landing-main-img" src={mainlogo} alt="" />
          <div className="league-page-search">
            <input
              id="outlined-basic"
              variant="outlined"
              className="league-page-search-input"
              fullWidth
              placeholder="Search"
              label="Search"
            />
            <div className="league-page-search-icon">
              <FaSearch />
            </div>
          </div>
          <div className="league-page-search-breadcrumb">
            {/* Home / Leagues / ABA League */}
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
          <div className="league-page-search-logo container">
            {/* <h2 className=" mt-5">
              <b> */}
            <img src={Abaleague} alt="" className="team-logo" />
            {/* LEAGUE
              </b>
            </h2> */}
          </div>
        </div>

        <div className="container">
          <div>
            {" "}
            <h2 className="container ">
              <img src={NewCibona} alt="" /> <b>ABA LEAGUE</b>
            </h2>
            <p className="container">
              Košarkaški klub Cibona, commonly referred to as Cibona Zagreb or
              simply Cibona, is a men's professional basketball club based in
              Zagreb, Croatia. The club is a founding member and shareholder of
              the Adriatic Basketball Association, and competes in the Adriatic
              League and the Croatian League.
            </p>
          </div>
          <div className="d-flex gap-3 mt-4">
            <button
              className="new-main-tab"
              data-bs-toggle="tab"
              data-bs-target="#tab-01"
              type="button"
              role="tab"
              aria-controls="tab-01"
              aria-selected="true"
              onClick={() => setAboutContainer(false)}
            >
              Calendar
              {/* {text_singlematchtickets} */}
            </button>
            <button
              className="new-main-tab"
              data-bs-toggle="tab"
              data-bs-target="#tab-02"
              type="button"
              role="tab"
              aria-controls="tab-02"
              aria-selected="false"
              onClick={handleClickAbout}
            >
              About
              {/* {text_seasonpass} */}
            </button>
          </div>
        </div>
        {aboutContainer && (
          <div className="league-page-about container">
            <div className="about-social-card">
              <FaXTwitter className="about-social-card-icon" />
              <span>@ABA_League</span>
            </div>
            <div className="about-social-card">
              <FaFacebookSquare className="about-social-card-icon" />
              <span>@ABA_League</span>
            </div>
            <div className="about-social-card">
              <FaYoutube className="about-social-card-icon" />
              <span>@ABA_League</span>
            </div>
            <div className="about-social-card">
              <FaInstagram className="about-social-card-icon" />
              <span>@ABA_League</span>
            </div>
            <div className="about-social-card">
              <FaTiktok className="about-social-card-icon" />
              <span>@ABA_League</span>
            </div>
            <div className="about-social-card">
              <FaLink className="about-social-card-icon" />
              <span>@ABA_League</span>
            </div>
          </div>
        )}
        <div className="col-xl-12 col-lg-12 col-md-12 container">
          <div className="main-title mt-3">
            <h4 className="fs-2 fw-bold">Teams</h4>
          </div>
        </div>
        <div className="league-page-team-container container">
          {team_name.map((item, index) => {
            return (
              <div className="league-page-team-card" key={index}>
                <img
                  src={item.image}
                  alt=""
                  className="league-page-team-card-img"
                />
                <p className="league-page-team-card-name">{item.name}</p>
              </div>
            );
          })}
        </div>
        <div className="main-title position-relative container mt-5">
          <h4 className="fs-2 fw-bold">All Games</h4>
          <div className="d-flex justify-content-between">
            <div className="d-flex gap-3">
              <div className="form-control filter-team d-flex">
                <MdDateRange className="icon-team" />
                <DatePicker
                  //   showIcon
                  className="h_40 mx-1 form-control form-control-BR w-100"
                  selected={startDate}
                  dateFormat="dd MMM,yyyy"
                  onChange={(date) => setStartDate(date)}
                />
              </div>
              <div className="form-control filter-team d-flex">
                <IoLocationOutline className="icon-team" />
                <input
                  type="text"
                  className="h_40 form-control form-control-BR w-100"
                  name=""
                  id=""
                  placeholder="Location"
                />
              </div>
            </div>
            <div className="d-flex justify-content-end gap-3">
              <div className="form-control filter-team d-flex">
                <BsFilterLeft className="icon-team" />
                <select
                  name=""
                  id=""
                  className="h_40 form-control form-control-BR w-100"
                >
                  <option value=""> Teams</option>
                </select>
              </div>
              <div className="form-control filter-team d-flex">
                <BsFilterLeft className="icon-team" />
                <input
                  type="text"
                  className="h_40 form-control form-control-BR w-100"
                  name=""
                  id=""
                  placeholder="Calendar View"
                />
              </div>
              <div className="form-control filter-team d-flex">
                <BsFilterLeft className="icon-team" />
                <select
                  name=""
                  id=""
                  className="h_40 form-control form-control-BR w-100"
                >
                  <option value=""> Sort</option>
                </select>
                {/* <input
                    type="text"
                    className="h_40 form-control form-control-BR w-100"
                    name=""
                    id=""
                    placeholder="Sort"
                  /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="new-card-team d-flex flex-wrap mt-3">
            <img src={img1} alt="" className="card-img-team" />
            <div className="m-3 responsive-team-card">
              <h3>Cibona vs Zadar</h3>
              <span>29 Nov, 2024 • Fri, 5:00AM</span> <br />
              <span>Dražen Petrović Basketball Hall • Zagreb, HR</span>
            </div>
            <button className="buybtn-team">Buy Tickets</button>
          </div>
          <div className="new-card-team d-flex flex-wrap mt-3">
            <img src={img1} alt="" className="card-img-team" />
            <div className="m-3 responsive-team-card">
              <h3>Cibona vs Zadar</h3>
              <span>29 Nov, 2024 • Fri, 5:00AM</span> <br />
              <span>Dražen Petrović Basketball Hall • Zagreb, HR</span>
            </div>
            <button className="buybtn-team">Buy Tickets</button>
          </div>
          <div className="new-card-team d-flex flex-wrap mt-3">
            <img src={img1} alt="" className="card-img-team" />
            <div className="m-3 responsive-team-card">
              <h3>Cibona vs Zadar</h3>
              <span>29 Nov, 2024 • Fri, 5:00AM</span> <br />
              <span>Dražen Petrović Basketball Hall • Zagreb, HR</span>
            </div>
            <button className="buybtn-team">Buy Tickets</button>
          </div>
          <div className="new-card-team d-flex flex-wrap mt-3">
            <img src={img1} alt="" className="card-img-team" />
            <div className="m-3 responsive-team-card">
              <h3>Cibona vs Zadar</h3>
              <span>29 Nov, 2024 • Fri, 5:00AM</span> <br />
              <span>Dražen Petrović Basketball Hall • Zagreb, HR</span>
            </div>
            <button className="buybtn-team">Buy Tickets</button>
          </div>
          <div className="new-card-team d-flex flex-wrap mt-3">
            <img src={img1} alt="" className="card-img-team" />
            <div className="m-3 responsive-team-card">
              <h3>Cibona vs Zadar</h3>
              <span>29 Nov, 2024 • Fri, 5:00AM</span> <br />
              <span>Dražen Petrović Basketball Hall • Zagreb, HR</span>
            </div>
            <button className="buybtn-team">Buy Tickets</button>
          </div>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </>
  );
}

export default LeaguePage;
