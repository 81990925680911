import React, { useEffect, useState } from "react";
import "../ResellEvent/ResellEvent.css";
import { Link, useNavigate } from "react-router-dom";
import { AllEventCategory, AllWeekLink } from "../../utils/data";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { FiBookmark } from "react-icons/fi";
import img1 from "../../assets/img/event-imgs/big-1.jpg";
import img2 from "../../assets/img/event-imgs/img-1.jpg";
import img3 from "../../assets/img/event-imgs/img-2.jpg";
import { GiTicket } from "react-icons/gi";
import { FaBookmark, FaCalendarAlt } from "react-icons/fa";
import { MdWatchLater } from "react-icons/md";
import { BsDot } from "react-icons/bs";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function ResellEvent() {
  const [Data, setData] = useState([]);
  const [nextToken, setNextToken] = useState("");
  const [hasMoreEvents, setHasMoreEvents] = useState("");
  const [indexBookmark, setIndexBookmark] = useState("");
  const navigate = useNavigate();
  const [text_resellticketcount, setText_resellticketcount] = useState(
    "Resell Ticket Count"
  );
  const [text_location, setText_location] = useState("Location");
  const [text_missedtickets, setText_missedtickets] = useState(
    "Missed out on tickets? Don't worry"
  );
  const [text_notice, setText_notice] = useState(
    "there might be someone ready to share their ticket with you"
  );

  const [text_noeventfound, setText_noeventfound] = useState("No Event found");
  const [text_seemore, setText_seemore] = useState("See More");
  useEffect(() => {
    var API_URL = API_DOMAIN + "ticket/resellticketbyevent";

    axios
      .get(API_URL)
      .then((response) => {
        console.log("all events ", response.data.data);
        var res = response.data.data;
        setData(res);
        setNextToken(res.nexttoken);
        setHasMoreEvents(res.hasMoreEvents);
        console.log("first", Data);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, [1000]);

  const getMoreEventCard = (nextToken, D) => {
    var type = "selectall";
    var API_URL =
      API_DOMAIN + `event/public?type=${type}&limit=12&nexttoken=${nextToken}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var res = response?.data?.data?.event;
        for (let i = 0; i < res.length; i++) {
          D.push(res[i]);
          setData(D);
        }
        setHasMoreEvents(res.hasMoreEvents);
        setNextToken(res.nexttoken);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };

  const AddBookmarkHandle = (eveID, eveData) => {
    var API_URL = API_DOMAIN + `event/bookmark`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    var data = {
      event_unique_id: eveID,
      seat_unique_id: "seat_unique_id",
      seat_category_id: "seat_category_id",
      isbookmark: true,
      event_details: [eveData],
    };
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log(response);
        if (response.data.message === "Bookmark created") {
          getBookmarkedEvents();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const RemoveBookmarkHandle = (eveID) => {
    var API_URL = API_DOMAIN + `event/uncheck/bookmark`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    var data = {
      event_unique_id: eveID,
      seat_unique_id: "seat_unique_id",
      seat_category_id: "seat_category_id",
      isbookmark: false,
    };
    axios
      .patch(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("Remove", response);
        if (response.data.message === "Bookmark updated") {
          getBookmarkedEvents();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const [bookmaredEventIdList, setBookmaredEventIdList] = useState([]);

  const getBookmarkedEvents = () => {
    var API_URL = API_DOMAIN + `event/get/bookmarks`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        // var res = response?.data?.data?.event;
        console.log("Bookmarked Events", response.data.data);
        setBookmaredEventIdList(response.data.data);
        var res = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const isBookmarked = (eventId) => {
    return bookmaredEventIdList.some(
      (event) => event.event_unique_id === eventId
    );
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_resellticketcount(
        xpath.select1("/resources/string[@name='text_resellticketcount']", doc)
          .firstChild.data
      );
      setText_location(
        xpath.select1("/resources/string[@name='text_location']", doc)
          .firstChild.data
      );
      setText_missedtickets(
        xpath.select1("/resources/string[@name='text_missedtickets']", doc)
          .firstChild.data
      );
      setText_notice(
        xpath.select1("/resources/string[@name='text_notice']", doc).firstChild
          .data
      );
      setText_noeventfound(
        xpath.select1("/resources/string[@name='text_noeventfound']", doc)
          .firstChild.data
      );
      setText_seemore(
        xpath.select1("/resources/string[@name='text_seemore']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div className="wrapper" id="otherPages">
        <div className="hero-banner">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-8 col-md-10">
                <div className="hero-banner-content">
                  <h1 className="hometextnight">{text_missedtickets}</h1>
                  <h3 className="hometextnight">{text_notice}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="explore-events p-80">
          <div className="container">
            <p>
              We're the world’s largest secondary marketplace for tickets to
              live events. Prices are set by sellers and may be below or above
              face value.
            </p>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="event-filter-items">
                  <div className="featured-controls">
                    {/* <div className="filter-tag">
                      {AllWeekLink.map((e) => {
                        return (
                          <>
                            <Link to={e.link} className={e.class}>
                              {e.value}
                            </Link>
                          </>
                        );
                      })}
                    </div>
                    <div className="controls">
                      {AllEventCategory.map((e) => {
                        return (
                          <>
                            <button
                              type="button"
                              className="control"
                              data-filter={e.datafilter}
                            >
                              {e.value}
                            </button>
                          </>
                        );
                      })}
                    </div> */}
                    {Data.length > 0 ? (
                      <>
                        <div className="row" data-ref="event-filter-content">
                          {Data.map((v, index) => {
                            return (
                              <>
                                <div
                                  className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mix arts concert workshops volunteer sports health_Wellness"
                                  data-ref="mixitup-target"
                                  id={index}
                                >
                                  <div className="main-card mt-4">
                                    <div className="event-thumbnail">
                                      <Link
                                        to={`/resell-ticket/${v?.event_unique_id}`}
                                        className="thumbnail-img"
                                      >
                                        <img
                                          src={
                                            v.eventsdetails[0]
                                              ?.EventImagePath === "path"
                                              ? img1
                                              : v.eventsdetails[0]
                                                  ?.EventImagePath
                                          }
                                          alt=""
                                        />
                                      </Link>
                                      <span
                                        className="bookmark-icon bookmark-content"
                                        title=""
                                        onClick={() => [
                                          setIndexBookmark(index),
                                        ]}
                                      >
                                        {isBookmarked(v.event_unique_id) ? (
                                          <>
                                            <FaBookmark
                                              className="bookmarkicon-fill"
                                              onClick={() =>
                                                RemoveBookmarkHandle(
                                                  v?.event_unique_id
                                                )
                                              }
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <FiBookmark
                                              className="bookmarkicon"
                                              onClick={() =>
                                                AddBookmarkHandle(
                                                  v?.event_unique_id,
                                                  v
                                                )
                                              }
                                            />
                                          </>
                                        )}
                                      </span>
                                    </div>
                                    <div className="event-content ">
                                      <Link
                                        to={`/resell-ticket/${v?.event_unique_id}`}
                                        className="event-title event-css"
                                      >
                                        {v?.eventsdetails[0]?.EventTitle}
                                      </Link>
                                      <div className="duration-price-remaining">
                                        <span className="duration-price">
                                          {text_location}:-&nbsp;
                                          {v?.EventVenueLocation}
                                          {
                                            v?.eventsdetails[0]?.venuedetails[0]
                                              .venuename
                                          }
                                          ,{" "}
                                          {
                                            v?.eventsdetails[0]?.venuedetails[0]
                                              .address
                                          }
                                          ,{" "}
                                          {
                                            v?.eventsdetails[0]?.venuedetails[0]
                                              .city
                                          }
                                          ,{" "}
                                          {
                                            v?.eventsdetails[0]?.venuedetails[0]
                                              .state
                                          }
                                          ,{" "}
                                          {
                                            v?.eventsdetails[0]?.venuedetails[0]
                                              .country
                                          }
                                          ,{" "}
                                          {
                                            v?.eventsdetails[0]?.venuedetails[0]
                                              .zipcode
                                          }
                                        </span>
                                        {/* <span className="remaining">
                                      {e.remaining === "" ? (
                                        ""
                                      ) : (
                                        <>
                                          <GiTicket /> {e.remaining}
                                        </>
                                      )}
                                    </span> */}
                                      </div>
                                      <div className="duration-price-remaining mt-2">
                                        <span className="duration-price">
                                          {text_resellticketcount}:-&nbsp;
                                          <span
                                            style={{
                                              fontSize: "21px",
                                              color: "#1d3f73",
                                            }}
                                          >
                                            {v?.count}
                                          </span>
                                        </span>
                                      </div>

                                      {/* <div>
                                    <span>
                                      Resell Ticket Count:{" "}
                                      {e.resellcount }
                                    </span>
                                  </div> */}
                                    </div>
                                    <div className="event-footer">
                                      <div className="event-timing">
                                        <div className="publish-date">
                                          <span>
                                            <FaCalendarAlt />{" "}
                                            {/* {moment(
                                              v?.eventsdetails[0]?.StartDate
                                            ).format("DD MMM,yyyy") ===
                                            moment(
                                              v?.eventsdetails[0]?.EndDate
                                            ).format("DD MMM,yyyy") ? (
                                              <> */}{" "}
                                            {moment
                                              .utc(
                                                v?.eventsdetails[0]?.StartDate
                                              )
                                              .format("DD MMM")}
                                            &nbsp;
                                            {/* </>
                                            ) : (
                                              <>
                                                {" "}
                                                {moment(
                                                  v?.eventsdetails[0]?.StartDate
                                                ).format("DD MMM")}{" "}
                                                -{" "}
                                                {moment(
                                                  v?.eventsdetails[0]?.EndDate
                                                ).format("DD MMM")}{" "}
                                                &nbsp;
                                              </>
                                            )} */}
                                          </span>
                                          <span className="dot">
                                            <BsDot
                                              style={{
                                                width: "23px",
                                                height: "23px",
                                              }}
                                            />
                                          </span>
                                          <span>
                                            {moment
                                              .utc(
                                                v.eventsdetails[0]?.StartDate
                                              )
                                              .format("ddd")}
                                            ,{" "}
                                            {moment
                                              .utc(
                                                v?.eventsdetails[0]?.StartTime
                                              )
                                              .format("hh:mm a")}{" "}
                                          </span>
                                        </div>
                                        <span className="publish-time">
                                          <MdWatchLater
                                            style={{
                                              width: "15px",
                                              height: "15px",
                                            }}
                                          />
                                          {new Date(
                                            moment.utc(
                                              v?.eventsdetails[0]?.EndTime
                                            )
                                          ).getHours() -
                                            new Date(
                                              moment.utc(
                                                v?.eventsdetails[0]?.StartTime
                                              )
                                            ).getHours()}{" "}
                                          h
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>{" "}
                              </>
                            );
                          })}
                        </div>
                        {hasMoreEvents ? (
                          <>
                            <div className="browse-btn">
                              <button
                                onClick={() =>
                                  getMoreEventCard(nextToken, Data)
                                }
                                className="main-btn btn-hover "
                              >
                                {text_seemore}
                              </button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <>
                        <div className="main-card mt-4">
                          <h3 className="text-center font-16 mt-3 fw-bold">
                            {text_noeventfound}
                          </h3>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ResellEvent;
