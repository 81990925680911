import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { FaTicketAlt } from "react-icons/fa";
import axios from "axios";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import { ToastContainer, toast } from "react-toastify";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function CreateEventConfiguration() {
  const navigate = useNavigate();
  const [venueSeatingDetails, setVenueSeatingDetails] = useState([]);
  const [eveData, setEveData] = useState([]);
  const data = useLocation();
  const eventID = data.state !== null ? data.state.event_id : 0;
  const [text_totalnumber_ticket, setText_totalnumber_ticket] = useState(
    "Total Number of Tickets"
  );
  const [text_total_ValOfTicket, setText_total_ValOfTicket] = useState(
    "Total Value Of All Ticket"
  );
  const [text_saveandpublish, setText_saveandpublish] =
    useState("Save & Publish");
  const [text_save, setText_save] = useState("Save");
  const [text_close, setText_close] = useState("Close");
  const [text_configureseats1, setText_configureseats1] =
    useState("Configure Seats");
  const [text_sectiontotal, setText_sectiontotal] = useState("Section Total");
  const [text_entrygate, setText_entrygate] = useState("Entry Gate");
  const [text_price, setText_price] = useState("Price");
  const [text_totalseats, setText_totalseats] = useState("Total Seats");
  const [text_sectionname, setText_sectionname] = useState("Section Name");
  const [text_configure, setText_configure] = useState("Configure Price");
  const [text_configurepriceandseats, setText_configurepriceandseats] =
    useState("Configure Price And Seats");
  const [text_configureeve, setText_configureeve] = useState("Configure Event");
  const [text_eveconfig, setText_eveconfig] = useState("Event Configuration");
  const [text_eveDetails, setText_eveDetails] = useState("Event Details");
  const [text_eveType, setText_eveType] = useState("Event Type");
  const [text_home, setText_home] = useState("Home");
  const [text_orgdashboard, setText_orgdashboard] = useState(
    "Organization Dashboard"
  );
  const [text_configuredseat, setText_configuredseat] =
    useState("Configured Seat");
  //   get Event details
  useEffect(() => {
    var type = "select";
    var event_unique_id = eventID;
    var API_URL =
      API_DOMAIN +
      "event/public?type=" +
      type +
      "&event_unique_id=" +
      event_unique_id;

    axios
      .get(API_URL)
      .then(function (response) {
        console.log("preview", response.data.data);
        setEveData(response.data.data);
        // setVenueSeatingDetails(response.data.data[0].TicketDetailsList);
        getSectionDetails(response.data.data[0].TicketDetailsList);
        //   setTableData(res[0].TicketDetailsList);
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  }, []);
  const getSectionDetails = (data) => {
    var API_URL =
      API_DOMAIN + `event/ticket/price/check?event_unique_id=${eventID}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        const res = response.data.data;
        const configured = res.filter((v) => {
          return v.isconfigure === true;
        });
        const notconfigured = res.filter((v) => {
          return v.isconfigure === false;
        });
        const dummy = data;
        for (let i = 0; i < dummy.length; i++) {
          for (let j = 0; j < configured.length; j++) {
            if (configured[j].seat_category_id === dummy[i].section_id) {
              dummy[i].min = configured[j].min;
              dummy[i].max = configured[j].max;
              dummy[i].configured = configured[j].total;
            }
          }
        }
        console.log(" Details", dummy);
        setVenueSeatingDetails(dummy);
        console.log("configured", configured);
        console.log("notconfigured", notconfigured);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };

  const publishValidation = () => {
    var count = 0;
    for (let i = 0; i < venueSeatingDetails.length; i++) {
      if (
        Number(
          (100 * venueSeatingDetails[i].configured) /
            venueSeatingDetails[i].no_tickets
        ) === Number(100)
      ) {
        count = count + 1;
        console.log(
          count,
          Number(
            (100 * venueSeatingDetails[i].configured) /
              venueSeatingDetails[i].no_tickets
          )
        );
        if (Number(count) === Number(venueSeatingDetails.length)) {
          publishEventHandle();
        }
      } else {
        toast.info("Please Configure Seats First!!");
      }
    }
  };
  const publishEventHandle = () => {
    console.log("sdasdas");
    var event_unique_id = eventID;
    var API_URL =
      API_DOMAIN + `event/update/publish?event_unique_id=${event_unique_id}`;
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = { isPublish: true };
    axios
      .put(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("published", response.data.data);
        navigate("/");
        // setEveData(response.data.data);
        // setVenueSeatingDetails(response.data.data[0].TicketDetailsList);
        //   setTableData(res[0].TicketDetailsList);
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_totalnumber_ticket(
        xpath.select1("/resources/string[@name='text_totalnumber_ticket']", doc)
          .firstChild.data
      );
      setText_total_ValOfTicket(
        xpath.select1("/resources/string[@name='text_total_ValOfTicket']", doc)
          .firstChild.data
      );
      setText_saveandpublish(
        xpath.select1("/resources/string[@name='text_saveandpublish']", doc)
          .firstChild.data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_close(
        xpath.select1("/resources/string[@name='text_close']", doc).firstChild
          .data
      );
      setText_configureseats1(
        xpath.select1("/resources/string[@name='text_configureseats1']", doc)
          .firstChild.data
      );
      setText_sectiontotal(
        xpath.select1("/resources/string[@name='text_sectiontotal']", doc)
          .firstChild.data
      );
      setText_entrygate(
        xpath.select1("/resources/string[@name='text_entrygate']", doc)
          .firstChild.data
      );
      setText_price(
        xpath.select1("/resources/string[@name='text_price']", doc).firstChild
          .data
      );
      setText_totalseats(
        xpath.select1("/resources/string[@name='text_totalseats']", doc)
          .firstChild.data
      );
      setText_sectionname(
        xpath.select1("/resources/string[@name='text_sectionname']", doc)
          .firstChild.data
      );
      setText_configure(
        xpath.select1("/resources/string[@name='text_configure']", doc)
          .firstChild.data
      );
      setText_configurepriceandseats(
        xpath.select1(
          "/resources/string[@name='text_configurepriceandseats']",
          doc
        ).firstChild.data
      );
      setText_configureeve(
        xpath.select1("/resources/string[@name='text_configureeve']", doc)
          .firstChild.data
      );
      setText_eveconfig(
        xpath.select1("/resources/string[@name='text_eveconfig']", doc)
          .firstChild.data
      );
      setText_eveDetails(
        xpath.select1("/resources/string[@name='text_eveDetails']", doc)
          .firstChild.data
      );
      setText_eveType(
        xpath.select1("/resources/string[@name='text_eveType']", doc).firstChild
          .data
      );
      setText_orgdashboard(
        xpath.select1("/resources/string[@name='text_orgdashboard']", doc).firstChild
          .data
      );
      setText_configuredseat(
        xpath.select1("/resources/string[@name='text_configuredseat']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div
        className="Create-event-wrapper eventdetail-nightmod"
        id="otherPages"
      >
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/org-dashboard">{text_orgdashboard}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/event-type">{text_eveType}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_eveconfig}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="event-dt-block p-80">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12">
                <div className="main-title text-center">
                  <h3>{text_configureeve}</h3>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9 col-md-12">
                <div className="wizard-steps-block">
                  <div id="add-event-tab" className="step-app">
                    <ul className="step-steps">
                      <li>
                        <Link to="#tab_step1">
                          <span className="number"></span>
                          <span className="step-name">{text_eveDetails}</span>
                        </Link>
                      </li>
                      <li></li>
                      <li className="active">
                        <Link to="#tab_step2">
                          <span className="number"></span>
                          <span className="step-name">{text_configureeve}</span>
                        </Link>
                      </li>
                    </ul>
                    {eveData.map((data) => {
                      return (
                        <>
                          <div className="tab-from-content">
                            <div className="main-card">
                              <div className="bp-title">
                                <h4>
                                  <FaTicketAlt className="step_icon me-3" />
                                  {text_configurepriceandseats}
                                </h4>
                              </div>
                              <div className="p-4 bp-form main-form">
                                <div className="form-group border_bottom-create profile-night-form pb_30">
                                  <div className="ticket-section">
                                    <label className="form-label fs-16">
                                      {text_configure}
                                    </label>
                                    <div className="content-holder seatscroll-season">
                                      <div className="row ">
                                        <table className="OwnerTable">
                                          <thead>
                                            <tr>
                                              <th className="OwnerTableHead">
                                                {text_sectionname}
                                              </th>
                                              <th className="OwnerTableHead">
                                                {text_totalseats}
                                              </th>

                                              <th className="OwnerTableHead">
                                                {text_price}(min-max)
                                              </th>
                                              <th className="OwnerTableHead">
                                                {text_entrygate}
                                              </th>
                                              {/* <th className="OwnerTableHead">
                                                {text_sectiontotal}
                                              </th> */}
                                              <th className="OwnerTableHead">
                                                {text_configuredseat} (%)
                                              </th>
                                              <th className="OwnerTableHead">
                                                {text_configureseats1}
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {" "}
                                            {venueSeatingDetails.map(
                                              (row, rowIndex) => {
                                                return (
                                                  <>
                                                    <tr key={1}>
                                                      <td className="OwnerTableBody p-2 ">
                                                        <input
                                                          type="text"
                                                          value={
                                                            row.ticket_category
                                                          }
                                                          className="mt-1 form-control h_25 res-create-seat-input landinginputnight"
                                                          disabled
                                                        />
                                                      </td>

                                                      <td className="OwnerTableBody p-2">
                                                        <input
                                                          type="number"
                                                          className="mt-1 form-control h_25 res-create-seat-price-input landinginputnight"
                                                          value={row.no_tickets}
                                                          disabled
                                                        />
                                                      </td>

                                                      <td className="OwnerTableBody p-2">
                                                        <div className="d-flex">
                                                          <input
                                                            type="text"
                                                            className="mt-1 form-control h_25 me-1 res-create-seat-price-input landinginputnight"
                                                            disabled
                                                            value={
                                                              data?.sell_currency
                                                            }
                                                          />
                                                          <input
                                                            value={`${JSON.stringify(
                                                              row.min
                                                            )} -${JSON.stringify(
                                                              row.max
                                                            )}`}
                                                            className="mt-1 form-control h_25 res-create-seat-price-input landinginputnight"
                                                            disabled
                                                          />
                                                        </div>
                                                      </td>
                                                      <td className="OwnerTableBody p-2 ">
                                                        <input
                                                          type="text"
                                                          value={row.entrygate}
                                                          className="mt-1 form-control h_25 res-create-seat-price-input landinginputnight"
                                                          disabled
                                                        />
                                                      </td>
                                                      {/* <td className="OwnerTableBody p-2">
                                                        <input
                                                          type="number"
                                                          value={
                                                            row.total_value
                                                          }
                                                          className="mt-1 form-control h_25"
                                                          disabled
                                                        />
                                                      </td> */}
                                                      <td className="OwnerTableBody p-2">
                                                        <input
                                                          value={`${
                                                            (100 *
                                                              row.configured) /
                                                            row.no_tickets
                                                          }%`}
                                                          className="mt-1 form-control h_25 res-create-seat-price-input landinginputnight"
                                                          disabled
                                                        />
                                                      </td>
                                                      <td className="OwnerTableBody p-2">
                                                        <button
                                                          className="steps_btn configure-btn res-config-btn"
                                                          onClick={() =>
                                                            navigate(
                                                              "/seat/configuration",
                                                              {
                                                                state: {
                                                                  event_id:
                                                                    eventID,
                                                                  section_id:
                                                                    row.section_id,
                                                                  sectionName:
                                                                    row.section,
                                                                  currency:
                                                                    data?.currency,
                                                                  sell_currency:
                                                                    data?.sell_currency,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        >
                                                          {text_configureseats1}
                                                        </button>
                                                      </td>
                                                    </tr>{" "}
                                                  </>
                                                );
                                              }
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="free-event pt_30">
                                    <div className="content-holder">
                                      <div className="form-group profile-night-form">
                                        <div className="row ce-border mt-3">
                                          <div className="col-lg-4 col-md-12">
                                            <div className="form-group border_bottom-create profile-night-form pb_30">
                                              <label className="form-label fs-16">
                                                {text_totalnumber_ticket}
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control h_50 landinginputnight"
                                                value={data?.TotalNumberTickets}
                                                disabled
                                              />
                                            </div>
                                          </div>
                                          {/* <div className="col-lg-4 col-md-12">
                                            <div className="form-group border_bottom-create profile-night-form pb_30">
                                              <label className="form-label fs-16">
                                                {text_total_ValOfTicket}
                                              </label>
                                              <div className="d-flex gap-2">
                                                <input
                                                  type="text"
                                                  className="form-control h_50 currency-input-create"
                                                  value={data.sell_currency}
                                                  disabled
                                                />
                                                <input
                                                  type="text"
                                                  className="form-control h_50"
                                                  value={data?.TotalAmount}
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}

                    <div className="d-flex flex-wrap gap-2 justify-content-center mt-4">
                      {/* className="step-footer step-tab-pager mt-4 " */}
                      <button
                        data-direction="prev"
                        className="btn cancelBtn me-2"
                        onClick={() => navigate(-1)}
                      >
                        {text_close}
                      </button>
                      <button
                        data-direction="next"
                        className="btn btn-default btn-hover steps_btn me-2"
                        onClick={() => navigate("/profile")}
                      >
                        {text_save}
                      </button>
                      <button
                        data-direction="finish"
                        className="btn btn-default btn-hover steps_btn Create-Conf-Btn"
                        onClick={publishValidation}
                      >
                        {text_saveandpublish}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </>
  );
}

export default CreateEventConfiguration;
