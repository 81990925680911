import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { GiTicket } from "react-icons/gi";
import { IoTime } from "react-icons/io5";
import { GoDotFill } from "react-icons/go";
import { FaBookmark, FaFacebook, FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { MdFileCopy, MdOutlineMail } from "react-icons/md";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FiBookmark } from "react-icons/fi";
import { HiUserCircle } from "react-icons/hi2";
import { MdDateRange } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import Eventimg from "../../assets/img/event-img.jpg";
import { AiOutlineShareAlt } from "react-icons/ai";
import { FaCalendarAlt } from "react-icons/fa";
import { RiVidiconLine } from "react-icons/ri";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import img1 from "../../assets/img/event-imgs/big-1.jpg";
import moment from "moment";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { BiLogIn } from "react-icons/bi";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function ResellEventDetail() {
  const Countdown = ({ targetDate }) => {
    const calculateTimeLeft = () => {
      const difference = new Date(targetDate) - new Date();
      let timeLeft = {};

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }

      return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);

      return () => clearTimeout(timer);
    });

    const { days, hours, minutes, seconds } = timeLeft;

    return (
      <div>
        {days !== undefined && days >= 0 ? (
           <h6
           style={{
             color: "white",
             textTransform: "lowercase",
             fontWeight: "700",
           }}
         >
            {days}d : {hours}h : {minutes}m : {seconds}s
          </h6>
        ) : (
          <h6 style={{ color: "white", fontSize: "18px" }}>
            {text_countdownfinished}!
          </h6>
        )}
      </div>
    );
  };

  const data = useLocation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const resellID = data.state !== null ? data.state.resell_id : 0;
  const ticketDetails =
    data.state !== null ? data.state.resell_ticket_details : 0;
  console.log("ticketDetails", ticketDetails, resellID);
  const navigate = useNavigate();
  const [eveDetails, setEveDetails] = useState([]);
  const [expirydatetime, setExpirydatetime] = useState([]);
  const [indexBookmark, setIndexBookmark] = useState("");
  const [openeventcopiedtooltip, setOpeneventcopiedtooltip] = useState(false);

  // language variable
  const [text_seat, setText_seat] = useState("Seat");
  const [text_section, setText_section] = useState("Section");
  const [text_orignal_price, setText_orignal_price] =
    useState("Original Price ");
  const [text_resellprice, setText_resellprice] = useState("Resell Price");
  const [text_reselltickets, setText_reselltickets] =
    useState("Resell Tickets");
  const [text_resellevents, setText_resellevents] = useState("Resell Events");
  const [text_countdownfinished, setText_countdownfinished] =
    useState("Countdown finished");
  const [text_home, setText_home] = useState("Home");
  const [text_reselleventdetailview, setText_reselleventdetailview] = useState(
    "Resell Event Detail View"
  );
  const [text_onlineevent, setText_onlineevent] = useState("Online Event");
  const [text_h, setText_h] = useState("h");
  const [text_save, setText_save] = useState("Save");
  const [text_share, setText_share] = useState("Share");
  const [text_insta, setText_insta] = useState("Instagram");
  const [text_facebook, setText_facebook] = useState("Facebook");
  const [text_twitter, setText_twitter] = useState("Twitter");
  const [text_linkedin, setText_linkedin] = useState("LinkedIn");
  const [text_email, setText_email] = useState("Email");
  const [text_aboutthisevent, setText_aboutthisevent] =
    useState("About This Event");
  const [text_eveDetails, setText_eveDetails] = useState("Event Details");
  const [text_info_please_login, setText_info_please_login] = useState(
    "Please Login To Proceed Further"
  );
  const [text_okay, setText_okay] = useState("Okay");
  const [text_booknow, setText_booknow] = useState("Book Now");
  const [text_location, setText_location] = useState("Location");
  const [text_expTime, setText_expTime] = useState("Expiry Time");
  const [text_expDate, setText_expDate] = useState("Expiry Date");
  const [text_time, setText_time] = useState("Time");
  const [text_date, setText_date] = useState("Date");
  const [text_addtocalender, setText_addtocalender] =
    useState("Add to Calendar");
  const [text_storytellers, setText_storytellers] = useState("Story Tellers");
  const [text_viewprofile, setText_viewprofile] = useState("View Profile");
  const [text_organisedby, setText_organisedby] = useState("Organised by");
  const [text_copyurl, setText_copyurl] = useState("Copy Event URL");
  const [text_startson, setText_startson] = useState("Starts on");
  useEffect(() => {
    var API_URL =
      API_DOMAIN + `ticket/tokendetails?reselltoken_unique_id=${resellID}`;

    axios
      .get(API_URL)
      .then(function (response) {
        var res = response?.data?.data;
        console.log(res);
        setExpirydatetime(res);
        getEventDetails(
          res?.ticket.event_unique_id,
          res?.ticket.ticket_category_id
        );
      })
      .catch(function (error) {
        console.log(error);
        // errorToast(err);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  }, []);
  const getEventDetails = (eveID, ticketID) => {
    console.log(ticketID);
    var type = "select";

    var API_URL =
      API_DOMAIN + "event/public?type=" + type + "&event_unique_id=" + eveID;
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var res = response?.data?.data;
        console.log(res);
        setEveDetails(res);
        var td = res[0].TicketDetailsList;
        console.log(td);
        let ans = td.filter((v, i) => {
          return v.ticket_category_id === ticketID;
        });
        console.log(">>>>>>>>>>>", ans);
      })
      .catch(function (error) {
        console.log(error);
        // errorToast(err);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const [openloginInfo, setOpenloginInfo] = useState(false);
  const handleCloseloginInfo = () => {
    setOpenloginInfo(false);
  };
  const loginCheckHandle = (id) => {
    var token = sessionStorage.getItem("token");
    if (token === null || token === undefined || token === "") {
      // console.log("!");
      // toast.info(<>{text_info_please_login}</>);
      setOpenloginInfo(true);
    } else {
      console.log("adasdasd", id);
      resellCheckoutHandle(id);
    }
  };
  const resellCheckoutHandle = (data) => {
    var API_URL = API_DOMAIN + "seat/resellcheckout";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    let body = { reselltoken_unique_id: resellID };
    axios
      .post(API_URL, body, headerConfig)
      .then(function (response) {
        // var res = response?.data?.data;
        console.log(response?.data?.data.client_secret);
        if (response.status === 200) {
          sessionStorage.setItem("resellEveDetail", JSON.stringify(data));
          sessionStorage.setItem(
            "resellTicketDetail",
            JSON.stringify(ticketDetails)
          );
          navigate("/resell-buy-ticket", {
            state: {
              event_details: data,
              ticket_details: ticketDetails,
              client_secret: response?.data?.data.client_secret,
              event_expiry: expirydatetime,
            },
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        // errorToast(err);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const handleCopyUrl = (sm) => {
    navigator.clipboard.writeText(window.location.href);
    // alert("link copied")
    // toast.info("Link copied to clipboard!");
    // handleTooltipOpen()
    if (sm === "instagram") {
      handleTooltipOpenInsta();
      setTimeout(() => {
        window.open("https://www.instagram.com/", "_blank", "noreferrer");
      }, 1000);
    } else if (sm === "linkedin") {
      handleTooltipOpenWhatsApp();
      setTimeout(() => {
        window.open("https://www.linkedin.com/", "_blank", "noreferrer");
      }, 1000);
    } else if (sm === "facebook") {
      handleTooltipOpenFacebook();
      setTimeout(() => {
        window.open("https://www.facebook.com/", "_blank", "noreferrer");
      }, 1000);
    } else if (sm === "twitter") {
      handleTooltipOpenTwitter();
      setTimeout(() => {
        window.open("https://twitter.com/", "_blank", "noreferrer");
      }, 1000);
    } else if (sm === "email") {
      handleTooltipOpenEmail();
      setTimeout(() => {
        window.open("https://gmail.com/", "_blank", "noreferrer");
        setOpenTooltip(false);
      }, 2000);
    }
    //  else if (sm === "copy") {
    //   handleTooltipOpenCopy();
    // }
  };
  const [openInsta, setOpenInsta] = React.useState(false);
  const [openFacebook, setOpenFacebook] = React.useState(false);
  const [openTwitter, setOpenTwitter] = React.useState(false);
  const [openLinkedin, setOpenLinkedin] = React.useState(false);
  const [opentooltip, setOpenTooltip] = useState(false);
  const [openEmail, setOpenEmail] = React.useState(false);

  const handleTooltipClose = () => {
    setOpenInsta(false);
    setOpenFacebook(false);
    setOpenLinkedin(false);
    setOpenTwitter(false);
    // setOpenCopy(false);
    setOpenTooltip(false);
  };

  const handleTooltipOpenWhatsApp = () => {
    setOpenLinkedin(true);
    setTimeout(() => {
      setOpenLinkedin(false);
    }, [2000]);
    setOpenTooltip(true);
  };

  const handleTooltipOpenInsta = () => {
    setOpenInsta(true);
    setTimeout(() => {
      setOpenInsta(false);
    }, [2000]);
    setOpenTooltip(true);
  };
  const handleTooltipOpenFacebook = () => {
    setOpenFacebook(true);
    setTimeout(() => {
      setOpenFacebook(false);
    }, [2000]);
    setOpenTooltip(true);
  };
  const handleTooltipOpenTwitter = () => {
    setOpenTwitter(true);
    setTimeout(() => {
      setOpenTwitter(false);
    }, [2000]);
    setOpenTooltip(true);
  };
  const handleTooltipOpenEmail = () => {
    setOpenEmail(true);
    setTimeout(() => {
      setOpenEmail(false);
    }, [1000]);
    setOpenTooltip(true);
  };
  const AddBookmarkHandle = (eveID, eveData) => {
    var API_URL = API_DOMAIN + `event/bookmark`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    var data = {
      event_unique_id: eveID,
      seat_unique_id: "seat_unique_id",
      seat_category_id: "seat_category_id",
      isbookmark: true,
      event_details: [eveData],
    };
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log(response);
        if (response.data.message === "Bookmark created") {
          getBookmarkedEvents();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const RemoveBookmarkHandle = (eveID) => {
    var API_URL = API_DOMAIN + `event/uncheck/bookmark`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    var data = {
      event_unique_id: eveID,
      seat_unique_id: "seat_unique_id",
      seat_category_id: "seat_category_id",
      isbookmark: false,
    };
    axios
      .patch(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("Remove", response);
        if (response.data.message === "Bookmark updated") {
          getBookmarkedEvents();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const [bookmaredEventIdList, setBookmaredEventIdList] = useState([]);

  const getBookmarkedEvents = () => {
    var API_URL = API_DOMAIN + `event/get/bookmarks`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        // var res = response?.data?.data?.event;
        console.log("Bookmarked Events", response.data.data);
        setBookmaredEventIdList(response.data.data);
        var res = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const isBookmarked = (eventId) => {
    return bookmaredEventIdList.some(
      (event) => event.event_unique_id === eventId
    );
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_date(
        xpath.select1("/resources/string[@name='text_date']", doc).firstChild
          .data
      );
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_info_please_login(
        xpath.select1("/resources/string[@name='text_info_please_login']", doc)
          .firstChild.data
      );
      setText_reselltickets(
        xpath.select1("/resources/string[@name='text_reselltickets']", doc)
          .firstChild.data
      );
      setText_startson(
        xpath.select1("/resources/string[@name='text_startson']", doc)
          .firstChild.data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_share(
        xpath.select1("/resources/string[@name='text_share']", doc).firstChild
          .data
      );
      setText_insta(
        xpath.select1("/resources/string[@name='text_insta']", doc).firstChild
          .data
      );
      setText_facebook(
        xpath.select1("/resources/string[@name='text_facebook']", doc)
          .firstChild.data
      );
      setText_twitter(
        xpath.select1("/resources/string[@name='text_twitter']", doc).firstChild
          .data
      );
      setText_linkedin(
        xpath.select1("/resources/string[@name='text_linkedin']", doc)
          .firstChild.data
      );
      setText_email(
        xpath.select1("/resources/string[@name='text_email']", doc).firstChild
          .data
      );
      setText_aboutthisevent(
        xpath.select1("/resources/string[@name='text_aboutthisevent']", doc)
          .firstChild.data
      );
      setText_eveDetails(
        xpath.select1("/resources/string[@name='text_eveDetails']", doc)
          .firstChild.data
      );
      setText_time(
        xpath.select1("/resources/string[@name='text_time']", doc).firstChild
          .data
      );
      setText_location(
        xpath.select1("/resources/string[@name='text_location']", doc)
          .firstChild.data
      );
      setText_booknow(
        xpath.select1("/resources/string[@name='text_booknow']", doc).firstChild
          .data
      );
      setText_resellevents(
        xpath.select1("/resources/string[@name='text_resellevents']", doc)
          .firstChild.data
      );
      setText_onlineevent(
        xpath.select1("/resources/string[@name='text_onlineevent']", doc)
          .firstChild.data
      );
      setText_h(
        xpath.select1("/resources/string[@name='text_h']", doc).firstChild.data
      );
      setText_countdownfinished(
        xpath.select1("/resources/string[@name='text_countdownfinished']", doc)
          .firstChild.data
      );
      setText_okay(
        xpath.select1("/resources/string[@name='text_okay']", doc).firstChild
          .data
      );
      setText_expTime(
        xpath.select1("/resources/string[@name='text_expTime']", doc).firstChild
          .data
      );
      setText_expDate(
        xpath.select1("/resources/string[@name='text_expDate']", doc).firstChild
          .data
      );
      setText_addtocalender(
        xpath.select1("/resources/string[@name='text_addtocalender']", doc)
          .firstChild.data
      );
      setText_reselleventdetailview(
        xpath.select1(
          "/resources/string[@name='text_reselleventdetailview']",
          doc
        ).firstChild.data
      );
      setText_storytellers(
        xpath.select1("/resources/string[@name='text_storytellers']", doc)
          .firstChild.data
      );
      setText_viewprofile(
        xpath.select1("/resources/string[@name='text_viewprofile']", doc)
          .firstChild.data
      );
      setText_organisedby(
        xpath.select1("/resources/string[@name='text_organisedby']", doc)
          .firstChild.data
      );
      setText_copyurl(
        xpath.select1("/resources/string[@name='text_copyurl']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div className="eventdetail-wrapper eventdetail-nightmod" id="otherPages">
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/resell-event">{text_resellevents}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/resell-ticket">{text_reselltickets}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_reselleventdetailview}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {eveDetails.map((v) => {
          return (
            <>
              <div className="event-dt-block p-80">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <div className="event-top-dts">
                        <div className="event-top-date">
                          <span className="event-month">
                            {moment.utc(v?.StartDate).format("MMM")}
                          </span>
                          <span className="event-date">
                            {moment.utc(v?.StartDate).format("DD")}
                          </span>
                        </div>
                        <div className="event-top-dt">
                          <h3 className="event-main-title">{v?.EventTitle}</h3>
                          <div className="event-top-info-status">
                            <span className="event-type-name">
                              <RiVidiconLine /> {text_onlineevent}
                            </span>
                            <span className="event-type-name details-hr">
                              {text_startson}{" "}
                              <span className="ev-event-date">
                                {moment
                                  .utc(v?.StartDate)
                                  .format("ddd, MMM DD, yyyy")}{" "}
                                {moment.utc(v?.StartTime).format("hh : mm A")}
                              </span>
                            </span>
                            <span className="event-type-name details-hr">
                              {new Date(moment.utc(v.EndTime)).getHours() -
                                new Date(
                                  moment.utc(v.StartTime)
                                ).getHours()}{" "}
                              {text_h}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 col-lg-7 col-md-12">
                      <div className="main-event-dt">
                        <div className="event-img">
                          <img
                            src={
                              v?.EventImagePath === "path"
                                ? img1
                                : v?.EventImagePath
                            }
                            alt=""
                            className="eventdetail-imghight"
                          />
                        </div>
                        <div className="share-save-btns dropdown">
                          <button
                            className="sv-btn me-2"
                            onClick={() => [setIndexBookmark(!indexBookmark)]}
                          >
                            {isBookmarked(v.event_unique_id) ? (
                              <>
                                <FaBookmark
                                  className="bookmarkicon-fill"
                                  onClick={() =>
                                    RemoveBookmarkHandle(v?.event_unique_id)
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <FiBookmark
                                  className="bookmarkicon"
                                  onClick={() =>
                                    AddBookmarkHandle(v?.event_unique_id, v)
                                  }
                                />
                              </>
                            )}
                            {text_save}
                          </button>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            arrow
                            open={opentooltip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="Copied!"
                            onClose={() => setOpenTooltip(false)}
                          >
                            <button
                              className="sv-btn me-2"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <AiOutlineShareAlt /> {text_share}
                            </button>
                          </Tooltip>

                          <ul className="dropdown-menu">
                            <li
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleTooltipClose}
                              open={openInsta}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              disableInteractive
                              arrow
                            >
                              <Link
                                className="dropdown-item"
                                to="#"
                                onClick={() => handleCopyUrl("instagram")}
                              >
                                <FaInstagram /> {text_insta}
                              </Link>
                            </li>
                            <li
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleTooltipClose}
                              open={openFacebook}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              disableInteractive
                              arrow
                            >
                              <Link
                                className="dropdown-item"
                                to="#"
                                onClick={() => handleCopyUrl("facebook")}
                              >
                                <FaFacebook /> {text_facebook}
                              </Link>
                            </li>
                            <li
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleTooltipClose}
                              open={openTwitter}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              disableInteractive
                              arrow
                            >
                              <Link
                                className="dropdown-item"
                                to="#"
                                onClick={() => handleCopyUrl("twitter")}
                              >
                                <FaTwitter /> {text_twitter}
                              </Link>
                            </li>
                            <li
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleTooltipClose}
                              open={openLinkedin}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              disableInteractive
                              arrow
                            >
                              <Link
                                className="dropdown-item"
                                to="#"
                                onClick={() => handleCopyUrl("linkedin")}
                              >
                                <FaLinkedinIn /> {text_linkedin}
                              </Link>
                            </li>
                            <li
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleTooltipClose}
                              open={openEmail}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              disableInteractive
                              arrow
                            >
                              <Link
                                className="dropdown-item"
                                to="#"
                                onClick={() => handleCopyUrl("email")}
                              >
                                <MdOutlineMail /> {text_email}
                              </Link>
                            </li>
                          </ul>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            arrow
                            open={openeventcopiedtooltip}
                            onClose={() => setOpeneventcopiedtooltip(false)}
                            title="Copied!"
                          >
                            <button
                              className="sv-btn p-2"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{ width: "max-content" }}
                              onClick={() => [
                                setOpeneventcopiedtooltip(true),
                                navigator.clipboard.writeText(
                                  window.location.href
                                ),
                              ]}
                            >
                              <MdFileCopy /> {text_copyurl}
                            </button>
                          </Tooltip>
                        </div>
                        <div className="main-event-content">
                          <h4>{text_aboutthisevent}</h4>
                          <p>{v?.EventDescription}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-5 col-md-12">
                      <div className="main-card event-right-dt">
                        <div className="bp-title">
                          <h4>{text_eveDetails}</h4>
                        </div>
                        <div className="time-left">
                          <div className="countdown">
                            <div className="countdown-item">
                              <span id="day">
                                <Countdown targetDate={v.StartDate} />
                              </span>
                            </div>
                            {/* <div className="countdown-item">
                              <span id="hour">8</span>Hours
                            </div>
                            <div className="countdown-item">
                              <span id="minute">22</span>Minutes
                            </div>
                            <div className="countdown-item">
                              <span id="second">9</span>Seconds
                            </div> */}
                          </div>
                        </div>
                        {/* <div className="event-dt-right-group mt-5">
                          <div className="event-dt-right-icon">
                            <HiUserCircle />
                          </div>
                          <div className="event-dt-right-content">
                            <h4>{text_organisedby}</h4>
                            <h5>{text_storytellers}</h5>
                            <Link to="/profile" className="event-linkstye">
                              {text_viewprofile}
                            </Link>
                          </div>
                        </div> */}
                        <div className="event-dt-right-group mt-5">
                          <div className="event-dt-right-icon">
                            <MdDateRange />
                          </div>
                          <div className="event-dt-right-content">
                            <h4>{text_date}</h4>
                            <h5>
                              {moment
                                .utc(v?.StartDate)
                                .format("ddd, MMM DD, yyyy")}{" "}
                              To{" "}
                              {moment
                                .utc(v?.EndDate)
                                .format("ddd, MMM DD, yyyy")}
                            </h5>
                            {/* <div className="add-to-calendar">
                              <Link
                                to="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                className="event-linkstye "
                              >
                                <FaCalendarAlt /> {text_addtocalender}
                              </Link>
                              <ul className="dropdown-menu">
                                <li>
                                  <Link className="dropdown-item" to="#">
                                    <i className="fa-brands fa-windows me-3"></i>
                                    Outlook
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="#">
                                    <i className="fa-brands fa-apple me-3"></i>
                                    Apple
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="#">
                                    <i className="fa-brands fa-google me-3"></i>
                                    Google
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="#">
                                    <i className="fa-brands fa-yahoo me-3"></i>
                                    Yahoo
                                  </Link>
                                </li>
                              </ul>
                            </div> */}
                          </div>
                        </div>
                        <div className="event-dt-right-group">
                          <div className="event-dt-right-icon">
                            <IoTime />
                          </div>
                          <div className="event-dt-right-content">
                            <h4>{text_time}</h4>
                            <h5>
                              {moment.utc(v?.StartTime).format("hh : mm A")} To{" "}
                              {moment.utc(v?.EndTime).format("hh : mm A")}
                            </h5>
                          </div>
                        </div>

                        <div className="event-dt-right-group">
                          <div className="event-dt-right-icon">
                            <MdDateRange />
                          </div>
                          <div className="event-dt-right-content">
                            <h4>{text_expDate}</h4>
                            <h5>
                              {moment(
                                expirydatetime?.ticket?.expiredatetime
                              ).format("ddd, MMM DD, yyyy")}
                            </h5>
                          </div>
                        </div>
                        <div className="event-dt-right-group">
                          <div className="event-dt-right-icon">
                            <IoTime />
                          </div>
                          <div className="event-dt-right-content">
                            <h4>{text_expTime}</h4>
                            <h5>
                              {moment(
                                expirydatetime?.ticket?.expiredatetime
                              ).format("hh : mm A")}
                            </h5>
                          </div>
                        </div>
                        <div className="event-dt-right-group">
                          <div className="event-dt-right-icon">
                            <IoLocationSharp />
                          </div>
                          <div className="event-dt-right-content">
                            <h4>{text_location}</h4>
                            <h5 className="mb-0">
                              {v?.venuedetails[0].venuename} ({" "}
                              {v?.venuedetails[0].address}, <br />
                              {v?.venuedetails[0].city},{" "}
                              {v?.venuedetails[0].state},{" "}
                              {v?.venuedetails[0].country},{" "}
                              {v?.venuedetails[0].zipcode})
                            </h5>
                          </div>
                        </div>
                        {/* <div className="d-flex">
                          <div className="event-dt-right-group">
                            <div className="event-dt-right-icon">
                              <FaMoneyCheckDollar />
                            </div>
                            <div className="event-dt-right-content">
                              <h4>AUD</h4>
                              <h5 className="mb-0">$50.00</h5>
                            </div>
                          </div>
                          </div> */}
                        {/* <div className="event-dt-right-group">
                    <div className="event-dt-right-icon">
                      <FaMoneyCheckDollar />
                    </div>
                    <div className="event-dt-right-content">
                      <h4>Resell Price</h4>
                      <h5 className="mb-0">$20.00</h5>
                    </div>
                  </div> */}
                        {/* for start venue event details */}
                        {/* <div class="select-tickets-block">
                    <h6>Select Tickets</h6>
                    <div class="select-ticket-action">
                      <div class="ticket-price">AUD $75.00</div>
                      <div class="quantity">
                        <div class="counter">
                          <span
                            class="down"
                            // onClick="decreaseCount(event, this)"
                          >
                            -
                          </span>
                          <input type="text" value="0" />
                          <span class="up" 
						//   onClick="increaseCount(event, this)"
						  >
                            +
                          </span>
                        </div>
                      </div>
                    </div>
                    <p>
                      2 x pair hand painted leather earrings 1 x glass of
                      bubbles / or coffee Individual grazing box / fruit cup
                    </p>
                    <div class="xtotel-tickets-count">
                      <div class="x-title">1x Ticket(s)</div>
                      <h4>
                        AUD <span>$0.00</span>
                      </h4>
                    </div>
                  </div> */}
                        {/* for end venue event details */}

                        <div className="booking-btn">
                          <button
                            // to="#"
                            className="main-btn btn-hover w-100"
                            onClick={() => loginCheckHandle(v)}
                          >
                            {text_booknow}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <center>
                      <div className="col-xl-8 col-lg-8 col-md-12 mt-3">
                        <div className=" p-4">
                          <div className="row ">
                            <div className="tablescroll">
                              <table className="TableSty mt-4">
                                <tr>
                                  <th className="TableHeadSty">{text_seat}</th>
                                  <th className="TableHeadSty">
                                    {text_section}
                                  </th>
                                  <th className="TableHeadSty">
                                    {text_orignal_price}
                                  </th>
                                  <th className="TableHeadSty">
                                    {text_resellprice}
                                  </th>
                                </tr>

                                <tr>
                                  <td className="TableBodySty">
                                    {ticketDetails[0].seat_name}
                                  </td>
                                  <td className="TableBodySty">
                                    {ticketDetails[0].seat_category}
                                  </td>
                                  <td className="TableBodySty">
                                    {ticketDetails[0].currency +
                                      " " +
                                      Number(ticketDetails[0].seat_price)
                                        .toFixed(2)
                                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                  </td>
                                  <td className="TableBodySty">
                                    {ticketDetails[0].currency +
                                      " " +
                                      Number(ticketDetails[0].seat_resell_price)
                                        .toFixed(2)
                                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                  </td>{" "}
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </center> */}
                  </div>
                </div>
              </div>
            </>
          );
        })}
        {/* <br /> */}
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={openloginInfo}
        onClose={handleCloseloginInfo}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-center">
          <BiLogIn className="Email-icon" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h3 className="text-center">{text_info_please_login}</h3>
            {/* <h5 className="text-center">
              We have sent you a link on your registered email or phone no. to
              verify your Account.
            </h5>
            <h5 className="text-center">
              Please check your Spam or Junk folder.
            </h5> */}
            <br />
            {/* <center>
              <Link onClick={() => []} style={{ cursor: "pointer" }}>
                <u>Resend verification link</u>
              </Link>
            </center> */}
          </DialogContentText>
          <Divider />
        </DialogContent>
        <>
          <div className="d-flex justify-content-center mb-3">
            <button
              className="main-btn btn-hover "
              onClick={handleCloseloginInfo}
            >
              {text_okay}
            </button>
          </div>
        </>
      </Dialog>
      <ToastContainer />
      <Footer />
    </>
  );
}

export default ResellEventDetail;
